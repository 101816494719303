import { QueryParams } from '@/api/apiSreshine/pagerduty/get.types';
import { BaseChartPieData } from '@/assets/base_components';
import json from '@/api/apiMock/data_files/pagerduty_incident_events.json';
import { getFilter, countAppNames } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { PagerdutyEventHistory } from '@/models/PagerdutyEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPieData = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      status: obj.data?.event?.data?.status,
      title: obj.data?.event?.data?.title,
      html_url: obj.data?.event?.data?.html_url,
      oncall_persons:
        obj.data?.event?.data?.assignees?.map(
          (assignee: any) => assignee.summary
        ) || '',
      event_time: obj.data?.event?.occurred_at,
    };
    return new PagerdutyEventHistory(json);
  });
  recent(all);
  const { events } = getFilter(all, queryParams);
  const header: any = [['name', 'count']];
  const data = countAppNames(events);
  return {
    data: header.concat(data) as BaseChartPieData,
  };
};
