import { DateWithZone } from '@/models/DateWithZone';
import { request } from '@/utils/api_client/sreshine';

export const getSelectableChannels = async (
  organization: string,
  appName: string,
  metrics: string | null
): Promise<
  {
    id: string;
    name: string;
    type: 'slack' | 'email';
    webhookUrl: string | null;
    email: string | null;
    createdAt: DateWithZone;
    selected: boolean;
  }[]
> => {
  const { data } = await request.get(
    `/products/notifications/monitors/selectable_channels`,
    { organization: organization, app_name: appName, metrics: metrics }
  );

  const notificationMonitors: {
    id: string;
    name: string;
    type: 'slack' | 'email';
    webhookUrl: string | null;
    email: string | null;
    createdAt: DateWithZone;
    selected: boolean;
  }[] =
    data.channels?.map((channel: any) => {
      return {
        id: channel.id,
        name: channel.name,
        type: channel.type,
        webhookUrl: channel.webhook_url,
        email: channel.email,
        createdAt: new DateWithZone(channel.created_at),
        selected: channel.selected,
      };
    }) || [];

  return notificationMonitors;
};
