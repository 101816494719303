import { urlQuery } from '@/utils/api_client/sreshine/params';

// サーバから返却されるLinkヘッダをシミュレートする。
export const getLinkHeaders = (searchParams: any, totalCount: number) => {
  const { url, page, perPage } = paginate(searchParams, totalCount);
  const link = createLinkHeader(url);
  const headers = { link, 'x-total-count': totalCount };
  return { headers, page, perPage };
};

const paginate = (searchParams: any, totalCount: number) => {
  const url = {
    last: '',
    next: '',
    prev: '',
    first: '',
  };
  // pager系のパラメータとそれ以外のパラメータに分ける。
  const { page: _page, perPage: _perPage, ...others } = searchParams;
  const page = _page ? parseInt(_page) : 1;
  const perPage = _perPage ? parseInt(_perPage) : 10;
  const totalPage = Math.ceil(totalCount / perPage);

  const queryParam = urlQuery(others);
  url.last = `http://dummy/?page=${totalPage}&per_page=${perPage}&${queryParam}`;
  if (page !== totalPage) {
    const nextPage = page + 1;
    url.next = `http://dummy/?page=${nextPage}&per_page=${perPage}&${queryParam}`;
  }
  if (page !== 1) {
    const prevPage = page - 1;
    url.prev = `http://dummy/?page=${prevPage}&per_page=${perPage}&${queryParam}`;
    url.first = `http://dummy/?page=1&per_page=${perPage}&${queryParam}`;
  }
  return { url, page, perPage };
};

const createLinkHeader = (url: any) => {
  let link = '';
  if (url.last) {
    link += `<${url.last}>; rel=last`;
  }
  if (url.next) {
    link += `, <${url.next}>; rel=next`;
  }
  if (url.prev) {
    link += `, <${url.prev}>; rel=prev`;
  }
  if (url.first) {
    link += `, <${url.first}>; rel=first`;
  }
  return link;
};
