import { AppName } from '@/models/AppName';
import { request } from '@/utils/api_client/sreshine';

export const get = async (organizationLabel: string) => {
  const { data } = await request.get('/organizations/product/index', {
    organizationLabel,
  });
  const appNames: AppName[] =
    data.products?.map((product: any) => new AppName(product)) || [];
  return { appNames };
};
