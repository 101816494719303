import { getLinkHeaders } from '@/api/apiMock/utils/pager/linkHeader';

export const getFilter = (events: any[], searchParams: any) => {
  // パラメータを取り出す
  const {
    // CurrentHeaderFilter
    organization,
    appName,
    startTime,
    endTime,
    // ParamSearch + Pager + Order
    ...others
  } = searchParams;

  // ParamSearch + Pager から Pager, Order を除外する
  delete others.page;
  delete others.perPage;
  delete others.order;
  delete others.orderBy;

  //
  // CurrentHeaderFilter
  //
  events = events.filter((event: any) => {
    if (
      organization !== undefined &&
      organization !== '' &&
      organization !== event.organization
    ) {
      return false;
    }
    if (appName !== undefined && appName !== '' && appName !== event.appName) {
      return false;
    }
    if (event.eventTime < startTime || event.eventTime > endTime) {
      return false;
    }
    return true;
  });

  //
  // ParamSearch
  //
  events = events.filter((model: any) => {
    for (const key in others) {
      if (
        others[key] !== '' &&
        model[key] !== others[key] &&
        typeof others[key] !== 'object'
      ) {
        return false;
      }
    }
    return true;
  });

  //
  // Pager
  //
  const { headers, page, perPage } = getLinkHeaders(
    searchParams,
    events.length
  );
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  events = events.slice(startIndex, endIndex);
  return { events, headers };
};
