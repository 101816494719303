import { request } from '@/utils/api_client/sreshine';
import { NotificationChannel } from '@/models/NotificationChannel';

export const getNotificationChannels = async (
  params: ParamsGetNotificationChannels
): Promise<NotificationChannel[]> => {
  const { data } = await request.get(
    `/products/notifications/channels/index`,
    params
  );
  const notificationChannels: NotificationChannel[] =
    data.channels?.map((channel: any) => new NotificationChannel(channel)) ||
    [];

  return notificationChannels;
};

export type ParamsGetNotificationChannels = {
  organization: string;
  app_name: string;
};
