import { request } from '@/utils/api_client/sreshine';

export const create = async (params: ParamsCreateIntegration): Promise<any> => {
  return await request.post('/products/integrations/datadog/create', params);
};

export type ParamsCreateIntegration = {
  organization: string;
  appName: string;
  region: string;
  srestApiKey: string;
  datadogApiKey: string;
  datadogApplicationKey: string;
};

// ParamsCreateIntegrationからorganizationとappNameを削除
export type ParamsCreate = Omit<
  ParamsCreateIntegration,
  'organization' | 'appName'
>;
