import json from '@/api/apiMock/data_files/aws_health_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/aws/health/get.types';
import { AwsHealthEventHistory } from '@/models/AwsHealthEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  const all = jsonData.map((obj: any) => {
    return new AwsHealthEventHistory(obj);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const awsHealthEventHistories = events as AwsHealthEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsHealthEventHistories,
    pager,
  };
};
