import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      timezone: 'Asia/Tokyo', // デフォルト値
      // timezone: 'UTC', // テスト用
    };
  },
  mutations: {
    setTimezone(state, timezone) {
      state.timezone = timezone;
    },
  },
  actions: {
    updateTimezone({ commit }, timezone) {
      commit('setTimezone', timezone);
    },
  },
  getters: {
    getTimezone: (state) => state.timezone,
  },
});
