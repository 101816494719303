import { request } from '@/utils/api_client/sreshine';

export const createNoticiationChannels = async (
  organization: string,
  appName: string,
  params: ParamsCreateNotificationChannel[]
): Promise<any> => {
  return await request.post(`/products/notifications/channels/create`, {
    organization,
    app_name: appName,
    channels: params,
  });
};

export type ParamsCreateNotificationChannel = {
  type: 'slack' | 'email';
  name: string;
  end_point: string;
};
