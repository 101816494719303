import { DateWithZone } from '@/models/DateWithZone';

export class SentryEventHistory {
  appName: string;
  eventId: string;
  projectName: string;
  title: string;
  url: string;
  transaction: string;
  environment: string;
  level: string;
  eventTime: DateWithZone | null;
  organization: string;
  taskIds: string[];
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.projectName = data.project_name || '';
    this.title = data.title || '';
    this.url = data.url || '';
    this.transaction = data.transaction || '';
    this.environment = data.environment || '';
    this.level = data.level || '';
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.taskIds = data.task_ids || [];
    this.json = data.json || '';
  }
}
