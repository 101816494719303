import { request } from '@/utils/api_client/sreshine';

export const createArn = async (params: ParamsCreateArn): Promise<any> => {
  return await request.post(`/products/integrations/aws_v2/settings/save_arn`, {
    ...params,
  });
};

export type ParamsCreateArn = {
  organization: string;
  app_name: string;
  arn: string;
};
