import { DateWithZone } from '@/models/DateWithZone';

export class ApiKey {
  apiKeyId: string;
  name: string;
  apiKey: string;
  description: string;
  createdAt: DateWithZone;

  constructor(data: any) {
    this.apiKeyId = data.api_key_id || '';
    this.name = data.name || '';
    this.apiKey = data.api_key || '';
    this.description = data.description || '';
    this.createdAt = new DateWithZone(data.created_at);
  }
}
