import { DateWithZone } from '@/models/DateWithZone';
import { snakeCases } from '@/utils/case_style';

// keyValueObjects オブジェクトのネスト構造を解除して1階層にして、key名でネストを表現する
// (例: key1: { key2: x }} => { key: key1[key2], value: x )
const flattenKeyName = (params: any) => {
  const keyValues = [];
  for (const [key, v] of Object.entries(params)) {
    let value = v as any;
    const typeChecker = Object.prototype.toString;
    if (Array.isArray(value)) {
      // 配列
      value.forEach((v) => {
        if (typeChecker.call(v) === '[object Object]') {
          // 配列の中に、keyValueのオブジェクトがある場合
          Object.entries(v).forEach((keyVal) => {
            const innerKey = keyVal[0];
            const innerVal = keyVal[1];
            keyValues.push({ key: `${key}[][${innerKey}]`, value: innerVal });
          });
        } else {
          // 通常の値 (string, number, date)
          keyValues.push({ key: `${key}[]`, value: v });
        }
      });
    } else if (value.constructor.name === DateWithZone) {
      keyValues.push({ key, value });
    } else if (value.constructor.name === 'File') {
      // ファイル
      keyValues.push({ key, value });
    } else if (typeChecker.call(value).slice(8, -1).toLowerCase() === 'date') {
      value = `${value.getFullYear()}-${
        value.getMonth() + 1
      }-${value.getDate()}`;
      keyValues.push({ key, value });
    } else if (typeof value === 'object') {
      // ネスト
      const rootKey = key;
      const childKeyValues = flattenKeyName(value);
      childKeyValues.forEach((childKeyValue) => {
        keyValues.push({
          key: `${rootKey}[${childKeyValue.key}]`,
          value: childKeyValue.value,
        });
      });
    } else {
      // 通常
      keyValues.push({ key, value });
    }
  }
  return keyValues;
};

const escape = (value: any) => {
  if (typeof value == 'string') {
    return value
      .replaceAll('#', '%23')
      .replaceAll('&', '%26')
      .replaceAll('+', '%2B');
  } else {
    return value;
  }
};

// urlQuery axiosが送信するためのパラメータを生成する (GET)
export const urlQuery = (params: any) => {
  // refが来た場合は、解除する
  if (
    params &&
    params.constructor &&
    params.constructor.name &&
    params.constructor.name === 'RefImpl'
  ) {
    params = params.value;
  }
  const snakeCaseParams = snakeCases(params);
  const keyValues = flattenKeyName(snakeCaseParams);
  const queries: string[] = [];
  keyValues.forEach((keyValue) => {
    if (keyValue.value !== null) {
      queries.push(`${escape(keyValue.key)}=${escape(keyValue.value)}`);
    }
  });
  return queries.join('&');
};

// axiosが送信するためのパラメータを生成する (POST/PUT/PATCH)
export const normalizeParams = (params: any) => {
  // refが来た場合は、解除する
  if (
    params &&
    params.constructor &&
    params.constructor.name &&
    params.constructor.name === 'RefImpl'
  ) {
    params = params.value;
  }
  return snakeCases(params);
};
