/* eslint-disable */
export const remove = async (
  organization: string,
  appName: string,
  apiKeyId: string
): Promise<any> => {
  console.log('delete!');
  return true;
};
/* eslint-enable */
