import { useSnackbarMessage } from '@/models/global/snackbarMessage';
import { i18nResponse } from '@/utils/locale';
import * as Sentry from '@sentry/vue';

export const parseResponse = (response: any) => {
  if (response.status === 200) {
    const error = undefined;
    const data = response.data;
    const headers = response.headers;
    return { error, data, headers };
  }
  const error = new Error(response.statusText);
  const data = {};
  return { error, data };
};

export const parseError = (
  _error: any,
  setFormErrors: (errors: { id: string; message: string }[]) => void
) => {
  const { setSnackbarMessage } = useSnackbarMessage();

  if (_error.code === 'ERR_NETWORK') {
    setSnackbarMessage({
      message: i18nResponse('system.network_error'),
      type: 'error',
    });
    return { error: { status: 500, data: {} }, data: {}, headers: {} };
  }

  const error = {
    status: _error.response.status,
    data: _error.response.data.message,
  };

  const data = _error.response?.data || {};
  const headers = _error.response?.headers || {};

  // snackbarやフォームにエラーを表示する
  let errorMessage = '';
  if (error.status === 500) {
    errorMessage = i18nResponse('system.render500');
  } else {
    // errors.dataがオブジェクトの場合
    if (typeof error.data === 'object') {
      const errors = [];
      const bases = [];
      for (const key in error.data) {
        if (key === 'base') {
          bases.push(error.data[key]);
        } else {
          errors.push({ id: key, message: error.data[key] });
        }
      }
      if (errors.length > 0) {
        setFormErrors(errors);
      }
      if (bases.length > 0) {
        errorMessage = bases.join('');
      }
    } else if (typeof error.data === 'string') {
      errorMessage = error.data;
    } else {
      errorMessage = i18nResponse('system.render500');
    }
  }

  if (errorMessage !== '') {
    setSnackbarMessage({ message: errorMessage, type: 'error' });
  }
  // sentry
  Sentry.captureException(new Error(_error));
  return { error, data, headers };
};
