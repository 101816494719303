import { DateWithZone } from '@/models/DateWithZone';

/*
 * mockのデータの日付を直近の日付に変更する
 */
export const recent = (events: any) => {
  const latestEventAt = getLatestAt(events);
  shiftLatest(events, latestEventAt);
  sort(events);
};

const getLatestAt = (events: any) => {
  // 直近の日付を取得
  let latestEventAt: DateWithZone | null = null;
  events.forEach((event: any) => {
    const eventAt = event.eventTime;
    if (latestEventAt === null || latestEventAt < eventAt) {
      latestEventAt = eventAt;
    }
  });
  return latestEventAt;
};

const shiftLatest = (events: any, latesrtEventAt: DateWithZone | null) => {
  if (latesrtEventAt === null) {
    return;
  }
  // 全く同じ時間にしてしまうと、ページング切り替え時に時間が過ぎてしまうので、どんなに最新でも5分前にする
  const currentTime = new DateWithZone().getTime() - 1000 * 60 * 5;
  const timeDiff = currentTime - latesrtEventAt.getTime();
  events.forEach((event: any) => {
    const eventAt = event.eventTime;
    if (eventAt) {
      const newTime = new DateWithZone(eventAt.getTime() + timeDiff);
      event.eventTime = newTime;
    }
  });
};

const sort = (events: any) => {
  // eventTimeでソートする(降順)
  events.sort((a: any, b: any) => {
    const eventAtA = a.eventTime;
    const eventAtB = b.eventTime;
    if (eventAtA < eventAtB) {
      return 1;
    }
    if (eventAtA > eventAtB) {
      return -1;
    }
    return 0;
  });
};
