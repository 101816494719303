import { createApp } from 'vue';
import App from './App.vue';
// plugins
import amplify from './config/amplify';
import router from './config/router';
import vuetify from './config/vuetify';
import { setupCalendar, DatePicker } from 'v-calendar';
// style
import 'v-calendar/style.css';
import '@/assets/stylesheets/index.scss';
import 'vuetify/styles';
// global state
import { keyCurrentUser, createCurrentUser } from '@/models/global/currentUser';
import {
  keyCurrentHeaderFilter,
  createCurrentHeaderFilter,
} from '@/models/global/currentHeaderFilter';
import { keyFormErrors, createFormErrors } from '@/models/global/formErrors';
import { keyHtmlHead, createHtmlHead } from '@/models/global/htmlHead';
import timezone from '@/models/global/timezone';

// sentry
import * as Sentry from '@sentry/vue';
import { useSettings } from '@/config/settings';

const app = createApp(App);

// plugins
amplify.initialize();
app.use(router);
app.use(vuetify);
app.use(setupCalendar, { locales: { ja: { masks: { L: 'YYYY-MM-DD' } } } });
app.component('DatePicker', DatePicker);

// global state
app.provide(keyCurrentUser, createCurrentUser());
app.provide(keyCurrentHeaderFilter, createCurrentHeaderFilter());
app.provide(keyFormErrors, createFormErrors());
app.provide(keyHtmlHead, createHtmlHead());
app.use(timezone);

// sentry
const { settings } = useSettings();
if (settings.sentry.dsn !== '' && settings.env !== 'localhost') {
  Sentry.init({
    app,
    dsn: settings.sentry.dsn,
    environment: settings.env,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [settings.env, settings.host, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

app.mount('#app');
