import { DateWithZone } from '@/models/DateWithZone';

export class AwsInspectorEcrImageEventHistory {
  organization: string;
  eventId: string;
  appName: string;
  description: string;
  severity: string;
  resourceIds: string[];
  vulnerabilityId: string[];
  packageNames: string[];
  remediations: string[];
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.description = data.description || '';
    this.severity = data.severity || '';
    this.resourceIds = data.resource_ids || [];
    this.vulnerabilityId = data.vulnerability_id || [];
    this.remediations = data.remediations || [];
    this.packageNames = data.package_names || [];
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || '';
  }
}
