export const defaultSettings = {
  env: import.meta.env.VITE_ENV || 'localhost',
  version: '1.0',
  host: '',
  sentry: {
    dsn: '',
  },
  amplify: {
    region: '',
    userPoolId: '',
    userPoolWebClientId: '',
  },
};
