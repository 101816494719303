import { request } from '@/utils/api_client/sreshine';

export const remove = async (
  organization: string,
  appName: string,
  apiKeyId: string
) => {
  return await request.delete(`/products/product_api_keys/delete`, {
    organization,
    app_name: appName,
    api_key_id: apiKeyId,
  });
};
