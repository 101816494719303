import { PagerdutyEventHistory } from '@/models/PagerdutyEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (sendParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/pagerduty/index',
    sendParams
  );
  const pagerdutyEventHistories: PagerdutyEventHistory[] =
    data.pagerduty_events?.map(
      (pagerdutyEventHistry: any) =>
        new PagerdutyEventHistory(pagerdutyEventHistry)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    pagerdutyEventHistories,
    pager,
  };
};
