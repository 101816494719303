import { QueryParams } from '@/api/apiSreshine/newrelic/get.types';
import { BaseChartLineData } from '@/assets/base_components';
import { DateWithZone } from '@/models/DateWithZone';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getTimelineData = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const data: any = [['Date', 'Product A', 'Product B']];
  for (let i = 1; i < 10; i++) {
    const day = new DateWithZone();
    day.setDate(day.getDate() - i);
    data.push([
      `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`,
      Math.floor(Math.random() * 10),
      Math.floor(Math.random() * 10),
    ]);
  }
  return {
    data: data as BaseChartLineData,
  };
};
