import { request } from '@/utils/api_client/sreshine';

export const certifyEmail = async (organization: string, appName: string, params: ParamsCertifyEmail): Promise<any> => {
  const res = await request.put(
    `/products/notifications/channels/certify_email`,
    { organization: organization, app_name: appName, token: params.token, email: params.email }
  );

  return !res.error;
}

export type ParamsCertifyEmail = {
  token: string;
  email: string;
};
