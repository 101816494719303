import json from '@/api/apiMock/data_files/aws_cloudwatch_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/aws/cloudwatch/get.types';
import { AwsCloudwatchEventHistory } from '@/models/AwsCloudwatchEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      alarm_name: obj.data?.alarmName || '',
      description: obj.data?.configuration?.description || '',
      status: obj.data?.state?.value || '',
      event_time: obj.occurred_at || null,
    };
    return new AwsCloudwatchEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const awsCloudwatchEventHistories = events as AwsCloudwatchEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsCloudwatchEventHistories,
    pager,
  };
};
