import jsonAwsCloudwatchEvents from '@/api/apiMock/data_files/aws_cloudwatch_events.json';
import jsonAwsEcsEvents from '@/api/apiMock/data_files/aws_ecs_events.json';
import jsonAwsGuarddutyEvents from '@/api/apiMock/data_files/aws_guardduty_events.json';
import jsonAwsHealthEvents from '@/api/apiMock/data_files/aws_health_events.json';
import jsonAwsInspectorEc2NetworkEvents from '@/api/apiMock/data_files/aws_inspector_ec2_events.json';
import jsonAwsInspectorEc2PackageEvents from '@/api/apiMock/data_files/aws_inspector_ec2_events.json';
import jsonAwsEcrImageEvents from '@/api/apiMock/data_files/aws_inspector_ecr_image_events.json';
import jsonSecurityhubEvents from '@/api/apiMock/data_files/aws_securityhub_events.json';
import jsonDatadogMonitorEvents from '@/api/apiMock/data_files/datadog_monitor_events.json';
import jsonPagerdutyIncidentEvents from '@/api/apiMock/data_files/pagerduty_incident_events.json';
import jsonSentryEvents from '@/api/apiMock/data_files/sentry_events.json';
import jsonNewRelicEvents from '@/api/apiMock/data_files/newrelic_events.json';
import {
  DetailQueryParams,
  EventClassConstructor,
  EventClassType,
} from '@/api/apiSreshine/event/detail/get.types';

const jsonDataAwsCloudwatchEvents = JSON.parse(
  JSON.stringify(jsonAwsCloudwatchEvents)
);
const jsonDataAwsEcsEvents = JSON.parse(JSON.stringify(jsonAwsEcsEvents));
const jsonDataAwsGuarddutyEvents = JSON.parse(
  JSON.stringify(jsonAwsGuarddutyEvents)
);
const jsonDataAwsHealthEvents = JSON.parse(JSON.stringify(jsonAwsHealthEvents));
const jsonDataAwsInspectorEc2NetworkEvents = JSON.parse(
  JSON.stringify(jsonAwsInspectorEc2NetworkEvents)
);
const jsonDataAwsInspectorEc2PackageEvents = JSON.parse(
  JSON.stringify(jsonAwsInspectorEc2PackageEvents)
);
const jsonDataAwsEcrImageEvents = JSON.parse(
  JSON.stringify(jsonAwsEcrImageEvents)
);
const jsonDataSecurityhubEvents = JSON.parse(
  JSON.stringify(jsonSecurityhubEvents)
);
const jsonDataDatadogMonitorEvents = JSON.parse(
  JSON.stringify(jsonDatadogMonitorEvents)
);
const jsonDataNewRelicEvents = JSON.parse(JSON.stringify(jsonNewRelicEvents));
const jsonDataPagerdutyIncidentEvents = JSON.parse(
  JSON.stringify(jsonPagerdutyIncidentEvents)
);
const jsonDataSentryEvents = JSON.parse(JSON.stringify(jsonSentryEvents));

export const get = async <T extends InstanceType<EventClassType>>(
  queryParams: DetailQueryParams,
  eventClassConstructor: EventClassConstructor<any>
): Promise<T> => {
  const eventType = queryParams.event_type;
  const jsonData = getJsonData(queryParams, eventType);
  const data = getData(queryParams, eventType, jsonData);
  const model: T = new eventClassConstructor(data);
  return model;
};

const getJsonData = (queryParams: DetailQueryParams, eventType: string) => {
  let jsonObject;
  switch (eventType) {
    case 'aws_cloudwatch':
      jsonObject = jsonDataAwsCloudwatchEvents.data;
      break;
    case 'aws_ecs':
      jsonObject = jsonDataAwsEcsEvents.data;
      break;
    case 'aws_guardduty':
      jsonObject = jsonDataAwsGuarddutyEvents.data;
      break;
    case 'aws_health':
      jsonObject = jsonDataAwsHealthEvents;
      break;
    case 'aws_securityhub':
      jsonObject = jsonDataSecurityhubEvents.data;
      break;
    case 'aws_inspector_ec2_network':
      jsonObject = jsonDataAwsInspectorEc2NetworkEvents.data;
      break;
    case 'aws_inspector_ec2_package':
      jsonObject = jsonDataAwsInspectorEc2PackageEvents.data;
      break;
    case 'aws_inspector_ecr_image':
      jsonObject = jsonDataAwsEcrImageEvents.data;
      break;
    case 'datadog':
      jsonObject = jsonDataDatadogMonitorEvents.data;
      break;
    case 'newrelic':
      jsonObject = jsonDataNewRelicEvents.data;
      break;
    case 'pagerduty':
      jsonObject = jsonDataPagerdutyIncidentEvents.data;
      break;
    case 'sentry':
      jsonObject = jsonDataSentryEvents.data;
      break;
    default:
      jsonObject = [];
      break;
  }
  const data = jsonObject.find(
    (obj: any) => obj.event_id === queryParams.event_id
  );
  if (data !== undefined) {
    return data;
  }
  if (jsonObject.length > 0) {
    return jsonObject[0];
  }
  return {};
};

const getData = (
  queryParams: DetailQueryParams,
  eventType: string,
  data: any
) => {
  switch (eventType) {
    case 'aws_cloudwatch':
      return {
        organization: 'metaps',
        event_id: queryParams.event_id,
        app_name: data?.app_name,
        alarm_name: data?.data?.alarmName || '',
        description: data?.data?.configuration?.description || '',
        status: data?.data?.state?.value || '',
        event_time: data?.occurred_at || null,
      };
    case 'aws_ecs':
      return {
        organization: 'metaps',
        event_id: queryParams.event_id,
        app_name: data?.data?.app_name,
        cluster_name: data?.data?.clusterArn
          ? data?.data?.clusterArn.split('/')[1]
          : '',
        stopped_reason: data?.data?.stoppedReason || '',
        group_name: data?.data?.group || '',
        task_definition: data?.data?.taskDefinitionArn
          ? data?.data?.taskDefinitionArn.split('/')[1]
          : '',
        revision: data?.data?.taskDefinitionArn
          ? data?.data?.taskDefinitionArn.split(':').pop()
          : '',
        status: data?.data?.lastStatus || '',
        event_time: data?.data?.updatedAt || null,
      };
    case 'aws_guardduty':
      return data || {};
    case 'aws_health':
      return data || {};
    case 'aws_securityhub':
      return {
        organization: 'metaps',
        event_id: data?.event_id,
        app_name: data?.app_name,
        standard_ids:
          data?.data?.Compliance?.AssociatedStandards?.map(
            (standard: any) => standard.StandardsId
          ) || [],
        status: data?.data?.Compliance?.Status || '',
        severity: data?.data?.Severity?.Label || '',
        security_control_id: data?.data?.Compliance?.securityControlId || '',
        description: data?.data?.Description || '',
        event_time: data?.occurred_at || null,
      };
    case 'aws_inspector_ec2_network':
      return data || {};
    case 'aws_inspector_ec2_package':
      return data || {};
    case 'aws_inspector_ecr_image':
      return data || {};
    case 'datadog':
      return {
        organization: 'metaps',
        event_id: queryParams.event_id,
        app_name: data?.app_name,
        priority: data?.priority,
        type: data?.data?.event_type,
        url: data?.source_url,
        target: data?.target,
        title: data?.title,
        status: data?.status,
        event_time: Number(data?.data?.last_updated),
      };
    case 'newrelic':
      return {
        event_id: queryParams.event_id,
        organization: 'metaps',
        app_name: 'demo',
        service_name: data.project,
        title: data.detail?.title,
        url: data.detail?.issueUrl,
        priority: data.detail?.priority,
        status: data.detail?.state,
        event_time: data.time,
      };
    case 'pagerduty':
      return {
        organization: 'metaps',
        event_id: queryParams.event_id,
        app_name: data?.app_name,
        status: data?.data?.event?.data?.status,
        title: data?.data?.event?.data?.title,
        html_url: data?.data?.event?.data?.html_url,
        oncall_persons:
          data?.data?.event?.data?.assignees?.map(
            (assignee: any) => assignee.summary
          ) || '',
        event_time: data?.data?.event?.occurred_at,
      };
    case 'sentry':
      return {
        organization: 'metaps',
        event_id: queryParams.event_id,
        app_name: data?.app_name,
        project_name: data?.project,
        title: data?.data?.event?.title,
        url: data?.data?.data?.event?.web_url,
        transaction: data?.data?.data?.event?.location,
        environment: data?.data?.data?.event?.environment,
        level: data?.data?.data?.event?.level,
        event_time: data?.data?.data?.event?.datetime,
      };
    default:
      return {};
  }
};
