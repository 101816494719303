import { DateWithZone } from '@/models/DateWithZone';

export class PagerdutyEventHistory {
  organization: string;
  eventId: string;
  appName: string;
  status: string;
  title: string;
  htmlUrl: string;
  oncallPersons: string;
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.status = data.status || '';
    this.title = data.title || '';
    this.htmlUrl = data.html_url || '';
    this.organization = data.organization || '';
    // 配列を文字列に変換する
    this.oncallPersons = data.oncall_persons
      ? data.oncall_persons.join(',')
      : '';
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || '';
  }
}
