import { BaseChartPieData } from '@/assets/base_components';
import json from '@/api/apiMock/data_files/datadog_monitor_events.json';
import { getFilter, countAppNames } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/datadog/get.types';
import { DatadogEventHistory } from '@/models/DatadogEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPieData = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: obj.app_name,
      priority: obj.priority,
      type: obj.data?.event_type,
      url: obj.source_url,
      target: obj.target,
      title: obj.title,
      status: obj.status,
      event_time: Number(obj.data.last_updated),
    };
    return new DatadogEventHistory(json);
  });
  recent(all);
  const { events } = getFilter(all, queryParams);
  const header: any = [['name', 'count']];
  const data = countAppNames(events);

  return {
    data: header.concat(data) as BaseChartPieData,
  };
};
