import { Amplify } from 'aws-amplify';
import { useSettings } from '@/config/settings';

const amplify = {
  initialize: () => {
    const { settings } = useSettings();

    Amplify.configure({
      Auth: {
        region: settings.amplify.region,
        userPoolId: settings.amplify.userPoolId,
        userPoolWebClientId: settings.amplify.userPoolWebClientId,
      },
    });
  },
};

export default amplify;
