import { request } from '@/utils/api_client/sreshine/';

export const updatePassword = async (params: ParamsUpdatePassword) => {
  // デバッグ用に1秒待つ
  const { error } = await request.put('/user/password', params);
  return { error };
};

export type ParamsUpdatePassword = {
  currentPassword: string;
  newPassword: string;
};
