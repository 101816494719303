import { request } from '@/utils/api_client/sreshine';

export const getMetricses = async (
  organization: string,
  appName: string
): Promise<{ key: string; name: string }[]> => {
  const { data } = await request.get(
    `/products/notifications/monitors/metricses`,
    { organization: organization, app_name: appName }
  );
  return (
    data.metricses?.map((metrics: any) => {
      return { key: metrics.key, name: metrics.name };
    }) || []
  );
};
