import { ParamsCreateApiKey } from '@/api/apiSreshine/product/api_keys/create';

/* eslint-disable */
export const create = async (
  organization: string,
  appName: string,
  params: ParamsCreateApiKey
): Promise<any> => {
  return true;
};
/* eslint-enable */
