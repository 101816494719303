import { request } from '@/utils/api_client/sreshine';

export const availableTypes = async (searchParams: SearchParams) => {
  const { data } = await request.get('/cost/available_types', searchParams);
  return data;
};
export type SearchParams = {
  startAt: string;
  endAt: string;
};
