/* eslint-disable */
export const getMetricses = async (
  organization: string,
  app_name: string,
): Promise<{ key: string; name: string }[]> => {
  const data = [
    { key: 'aws.ecs.task_failure', name: 'ECSタスク異常終了', service: 'Amazon ECS' },
    {
      key: 'aws.guardduty.severity_high',
      name: '異常検知した重要度の高いアクティビティ',
      service: 'Amazon GuardDuty',
    },
    {
      key: 'aws.health.event',
      name: 'アカウントに影響する可能性のあるイベント',
      service: 'AWS Health',
    },
    { key: 'aws.cloudwatch.alarm', name: 'CloudWatchアラーム', service: 'Amazon CloudWatch' },
  ];

  return data;
};
/* eslint-enable */
