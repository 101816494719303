import { DateWithZone } from '@/models/DateWithZone';

export class NewRelicEventHistory {
  organization: string;
  eventId: string;
  appName: string;
  title: string;
  url: string;
  priority: string;
  status: string;
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.title = data.title || '';
    this.url = data.url || '';
    this.priority = data.priority || '';
    this.status = data.status || '';
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || '';
  }
}
