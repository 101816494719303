import { request } from '@/utils/api_client/sreshine';

export const deleteNoticiationChannel = async (
  organization: string,
  appName: string,
  channelId: string
): Promise<any> => {
  return await request.delete(`/products/notifications/channels/delete`, {
    organization: organization,
    app_name: appName,
    channel_id: channelId,
  });
};
