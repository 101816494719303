export class IntegrationDatadog {
  region: string;
  srestApiKey: string;
  datadogApiKey: string;
  datadogApplicationKey: string;
  constructor(data: any) {
    this.region = data.region || '';
    this.srestApiKey = data.srest_api_key || '';
    this.datadogApiKey = data.datadog_api_key || '';
    this.datadogApplicationKey = data.datadog_application_key || '';
  }
}
