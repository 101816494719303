import { request } from '@/utils/api_client/sreshine';

export const reSendEmailVerification = async (
  organization: string,
  appName: string,
  email: string,
): Promise<any> => {
  await request.post(
    `/products/notifications/channels/re_send_verification_mail`,
    { organization: organization, app_name: appName, email: email },
  );

  return true;
};
