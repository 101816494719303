import json from '@/api/apiMock/data_files/aws_ecs_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/aws/ecs/get.types';
import { AwsEcsEventHistory } from '@/models/AwsEcsEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      cluster_name: obj.data?.clusterArn
        ? obj.data?.clusterArn.split('/')[1]
        : '',
      stopped_reason: obj.data?.stoppedReason || '',
      group_name: obj.data?.group || '',
      task_definition: obj.data?.taskDefinitionArn
        ? obj.data.taskDefinitionArn.split('/')[1]
        : '',
      revision: obj.data?.taskDefinitionArn
        ? obj.data?.taskDefinitionArn.split(':').pop()
        : '',
      status: obj.data?.lastStatus || '',
      event_time: obj.data?.updatedAt || null,
    };
    return new AwsEcsEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const awsEcsEventHistories = events as AwsEcsEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsEcsEventHistories,
    pager,
  };
};
