import { DateWithZone } from '@/models/DateWithZone';
import { NotificationChannel } from '@/models/NotificationChannel';
import { ParamsGetNotificationChannels } from '@/api/apiSreshine/product/notification/getNotificationChannels';

/* eslint-disable */
export const getNotificationChannels = async (
  params: ParamsGetNotificationChannels,
): Promise<NotificationChannel[]> => {
  const data = [
    new NotificationChannel({
      id: 'aioh8uap',
      name: 'sample-notification-channel-1',
      type: 'slack',
      webhookUrl:
        'https://hooks.slack.com/services/xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      email: null,
      status: 'certified',
      createdAt: new DateWithZone(),
    }),
  ];

  return data;
};
/* eslint-enable */
