<template>
  <template v-if="props.isLoading">
    <table class="c-loading">
      <thead>
        <tr>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in [1, 2, 3, 4, 5]" :key="i">
          <td v-if="i == 3" class="chart-loading"></td>
          <td v-else></td>
        </tr>
      </tbody>
    </table>
  </template>
  <template v-else>
    <slot></slot>
  </template>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
</script>
