import { QueryParams } from '@/api/apiSreshine/aws/cloudwatch/get.types';
import { BaseChartPieData } from '@/assets/base_components';
import json from '@/api/apiMock/data_files/aws_cloudwatch_events.json';
import { getFilter, countAppNames } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { AwsCloudwatchEventHistory } from '@/models/AwsCloudwatchEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

export const getPieData = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      alarm_name: obj.data?.alarmName || '',
      description: obj.data?.configuration?.description || '',
      status: obj.data?.state?.value || '',
      event_time: obj.occurred_at || null,
    };
    return new AwsCloudwatchEventHistory(json);
  });
  recent(all);
  const { events } = getFilter(all, queryParams);
  const header: any = [['name', 'count']];
  const data = countAppNames(events);
  return {
    data: header.concat(data) as BaseChartPieData,
  };
};
