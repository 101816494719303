import { AwsHealthEventHistory } from '@/models/AwsHealthEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/health/index',
    queryParams
  );
  const awsHealthEventHistories: AwsHealthEventHistory[] =
    data.aws_health_events?.map(
      (awsEcsEventHistory: any) => new AwsHealthEventHistory(awsEcsEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsHealthEventHistories,
    pager,
  };
};
