import { DateWithZone } from '@/models/DateWithZone';

export class AwsEcsEventHistory {
  organization: string;
  eventId: string;
  appName: string;
  productName: string;
  clusterName: string;
  stoppedReason: string;
  groupName: string;
  taskDefinition: string;
  revision: string;
  status: string;
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.productName = data.product_name || '';
    this.clusterName = data.cluster_name || '';
    this.stoppedReason = data.stopped_reason || '';
    this.groupName = data.group_name || '';
    this.taskDefinition = data.task_definition || '';
    this.revision = data.revision;
    this.status = data.status || '';
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || '';
  }
}
