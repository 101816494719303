import { DateWithZone } from '@/models/DateWithZone';

export class AwsRdsEventHistory {
  organization: string;
  eventId: string;
  appName: string;
  sourceType: string;
  message: string;
  sourceIdentifier: string;
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.sourceType = data.source_type || '';
    this.message = data.message || '';
    this.sourceIdentifier = data.source_identifier || [];
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || '';
  }
}
