import { QueryParams } from '@/api/apiSreshine/sentry/get.types';
import { BaseChartPieData } from '@/assets/base_components';
import json from '@/api/apiMock/data_files/sentry_events.json';
import { getFilter, countAppNames } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { SentryEventHistory } from '@/models/SentryEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPieData = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      project_name: obj.project,
      title: obj.data?.data?.event?.title,
      url: obj.data?.data?.event?.web_url,
      transaction: obj.data?.data?.event?.location,
      environment: obj.data?.data?.event?.environment,
      level: obj.data?.data?.event?.level,
      event_time: obj.data?.data?.event?.datetime,
    };
    return new SentryEventHistory(json);
  });
  recent(all);
  const { events } = getFilter(all, queryParams);
  const header: any = [['name', 'count']];
  const data = countAppNames(events);
  return {
    data: header.concat(data) as BaseChartPieData,
  };
};
