export class DatadogSloEventHistory {
  appName: string;
  topics: {
    topicName: string;
    scores: {
      days: number;
      sli: number;
      slo: number;
      eblr: number;
    }[];
  }[];
  constructor(data: any) {
    this.appName = data.app_name;
    this.topics = data.topics.map((topic: any) => {
      return {
        topicName: topic.topic_name,
        scores: topic.scores.map((score: any) => {
          return {
            days: Number(score.days),
            sli: Number(score.sli),
            slo: Number(score.slo),
            eblr: Number(score.eblr),
          };
        }),
      };
    });
  }
}
