import { OncallMessage } from '@/models/OncallMessage';

export type ParamsOncallMessage = {
  title: string;
  description: string;
  timeStamp: Date | null;
};

export const post = async (params: OncallMessage) => {
  return { params };
};
