import { DateWithZone } from '@/models/DateWithZone';
import { ApiKey } from '@/models/ApiKey';
import { ParamsGetApiKeys } from '@/api/apiSreshine/product/api_keys/get';

export const get = async (params: ParamsGetApiKeys) => {
  const apiKeys = [
    new ApiKey({
      api_key_id: 'aioh8uap',
      name: `sample-api-key-1-${params.appName}`,
      api_key: 'aaaaaaaaaaaaaaaaaaaaaaaa',
      description: 'これはサンプルのAPIキーです',
      createdAt: new DateWithZone(),
    }),
  ];
  return apiKeys;
};
