import { request } from '@/utils/api_client/sreshine';

export const saveCosts = async (params: ParamsSaveCosts): Promise<any> => {
  return await request.post(`/products/integrations/aws_v2/costs/create`, {
    ...params,
  });
};

export type ParamsSaveCosts = {
  organization: string;
  app_name: string;
  services: object;
};
