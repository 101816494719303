import json from '@/api/apiMock/data_files/datadog_slo_events.json';
import { QueryParams } from '@/api/apiSreshine/datadogSlo/get.types';
import { DatadogSloEventHistory } from '@/models/DatadogSloEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const datadogSloEventHistories = jsonData.map((data: any) => {
    return new DatadogSloEventHistory(data);
  });

  return { datadogSloEventHistories };
};
