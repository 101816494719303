import { SearchParams } from '@/api/apiSreshine/cost/summary.types';

export const summary = async (searchParams: SearchParams) => {
  // console.log(searchParams);
  const data = {
    header: ['name', 'count'],
    data: [] as { name: string; value: number }[],
    oldData: searchParams.isCompare
      ? ([] as { name: string; value: number }[])
      : undefined,
  };
  if (searchParams.services.includes('Product A')) {
    data.data.push({ name: 'Product A', value: 510 });
    if (searchParams.isCompare && data.oldData) {
      data.oldData.push({ name: 'Product A', value: 500 });
    }
  }
  if (searchParams.services.includes('Product B')) {
    data.data.push({ name: 'Product B', value: 28 });
    if (searchParams.isCompare && data.oldData) {
      data.oldData.push({ name: 'Product B', value: 30 });
    }
  }
  if (searchParams.services.includes('Product C')) {
    data.data.push({ name: 'Product C', value: 132 });
    if (searchParams.isCompare && data.oldData) {
      data.oldData.push({ name: 'Product C', value: 100 });
    }
  }
  return {
    data,
  };
};
