import { AwsSecurityHubEventHistory } from '@/models/AwsSecurityHubEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/securityhub/index',
    queryParams
  );
  const awsSecurityHubEventHistories: AwsSecurityHubEventHistory[] =
    data.aws_securityhub_events?.map(
      (awsSecurityHubEventHistory: any) =>
        new AwsSecurityHubEventHistory(awsSecurityHubEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsSecurityHubEventHistories,
    pager,
  };
};
