import { Notice } from '@/models/Notice';
import { Pager } from '@/models/Pager';
import json from '@/api/apiMock/data_files/notices.json';
const jsonData = JSON.parse(JSON.stringify(json));

export const get = async () => {
  const allModels: Notice[] = jsonData.data?.map((obj: any) => {
    const json = {
      title: obj.title,
      description: obj.description,
      time_stamp: obj.time_stamp,
    };
    return new Notice(json);
  });

  const notices = allModels as Notice[];

  const pager = new Pager();
  pager.totalCount = jsonData.data.length;

  return {
    notices,
    pager,
  };
};
