import { QueryParams } from './get.types';
import { request } from '@/utils/api_client/sreshine';
import { BaseChartPieData } from '@/assets/base_components';

export const getPieData = async (queryParams: QueryParams) => {
  const { data, error } = await request.get(
    '/event/aws/securityhub/pie',
    queryParams
  );
  if (error) {
    return { data: [] };
  }
  return {
    data: data as BaseChartPieData,
  };
};
