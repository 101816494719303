import { ParamsGetIntegrations } from '@/api/apiSreshine/product/integration/get';
import { IntegrationList } from '@/models/IntegrationList';

/* eslint-disable */
export const get = async (params: ParamsGetIntegrations) => {
  const dataIntegrations = {
    new_relic: {
      api_key: 'api_key',
      account_id: 'account_id',
    },
    aws: {
      api_key: 'api_key',
      account_id: 'account_id',
    },
  };
  return new IntegrationList(dataIntegrations);
};
/* eslint-enable */
