import { request } from '@/utils/api_client/sreshine';

export const updateNotificationMonitor = async (
  organization: string,
  appName: string,
  monitors: ParamsUpdateNotificationMonitor[]
): Promise<any> => {
  return await request.put(`/products/notifications/monitors/update`, {
    organization: organization,
    app_name: appName,
    monitors,
  });
};

export type ParamsUpdateNotificationMonitor = {
  metrics: string;
  channel_ids?: string[];
  active?: boolean;
};
