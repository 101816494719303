import { NewRelicEventHistory } from '@/models/NewRelicEventHistory';
import { QueryParams } from './get.types';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/newrelic/index',
    queryParams
  );
  const newRelicEventHistories: NewRelicEventHistory[] =
    data.newrelic_events?.map(
      (newRelicEventHistory: any) =>
        new NewRelicEventHistory(newRelicEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    newRelicEventHistories,
    pager,
  };
};
