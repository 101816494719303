import { AppName } from '@/models/AppName';

export class Organization {
  label: string;
  name: string;
  appNames: AppName[];
  constructor(data: any) {
    this.label = data.label || '';
    this.name = data.name || '';
    this.appNames =
      data.app_names?.map((appName: any) => new AppName(appName)) || [];
  }
}
