type Base64 = string;

interface Params {
  image: Base64;
  filename: string;
}

export const post = async (params: Params) => {
  return params;
};
