import { DatadogEventHistory } from '@/models/DatadogEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/datadog/index',
    queryParams
  );
  const datadogEventHistories: DatadogEventHistory[] =
    data.datadog_events?.map(
      (datadogEvent: any) => new DatadogEventHistory(datadogEvent)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    datadogEventHistories,
    pager,
  };
};
