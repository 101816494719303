import { DateWithZone } from '@/models/DateWithZone';

export class OncallMessage {
  title: string;
  description: string;
  timeStamp: DateWithZone | null;

  constructor(data: any) {
    this.title = data.title || '';
    this.description = data.description || '';
    this.timeStamp = data.time_stamp ? new DateWithZone(data.time_stamp) : null;
  }
}
