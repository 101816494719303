import { DateWithZone } from '@/models/DateWithZone';

export class NotificationMonitor {
  metrics: {
    key: string;
    name: string;
    service: string;
  };
  channels: {
    id: string;
    name: string;
    type: "slack" | "email";
    webhookUrl: string | null;
    to: string | null;
    createdAt: DateWithZone;
  }[];
  active: boolean;
  createdAt: DateWithZone;

  constructor(data: any) {
    this.metrics = data.metrics || '';
    this.channels = data.channels.map((channel: any) => {
      return {
        'id': channel.id,
        'name': channel.name,
        'type': channel.type,
        'webhookUrl': channel.webhook_url || null,
        'to': channel.to || null,
        'createdAt': new DateWithZone(channel.created_at)
      };
    }) || [];
    this.active = data.active;
    this.createdAt = new DateWithZone(data.created_at);
  }
}
