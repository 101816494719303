import { AwsRdsEventHistory } from '@/models/AwsRdsEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/rds/index',
    queryParams
  );
  const awsRdsEventHistories: AwsRdsEventHistory[] =
    data.aws_rds_events?.map(
      (awsRdsEventHistory: any) => new AwsRdsEventHistory(awsRdsEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsRdsEventHistories,
    pager,
  };
};
