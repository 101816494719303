export class Pager {
  currentPage: number;
  totalPage: number;
  perPageCount: number;
  totalCount: number;

  constructor() {
    this.currentPage = 1;
    this.totalPage = 1;
    this.perPageCount = 10;
    this.totalCount = 0;
  }

  //
  // ページング情報をLinkヘッダから設定する
  //
  setLinkHeader(headers: any) {
    const linkHeader = headers['link'];
    // prevからcurrentを取得しないといけない
    if (linkHeader == undefined) return;

    this.currentPage = this.getCurrentPage(linkHeader) || 1;
    this.totalPage = this.getTotalPage(linkHeader) || 1;
    this.perPageCount = this.getPerPage(linkHeader) || 10;
    this.totalCount = parseInt(headers['x-total-count']);
  }
  getCurrentPage(linkHeader: string) {
    const { page } = this.getParamsFromLinkHeader(linkHeader, 'prev');
    if (page) {
      return page + 1;
    }
    return 1;
  }
  getTotalPage(linkHeader: string) {
    const { page } = this.getParamsFromLinkHeader(linkHeader, 'last');
    return page;
  }
  getPerPage(linkHeader: string) {
    const { perPage } = this.getParamsFromLinkHeader(linkHeader, 'last');
    return perPage;
  }
  getParamsFromLinkHeader(
    linkHeader: string,
    linkHeaderRel: 'first' | 'prev' | 'next' | 'last'
  ) {
    let page: number | undefined;
    let perPage: number | undefined;
    const linkItems = linkHeader.split(',');
    const linkItem = linkItems.find((linkItem) =>
      new RegExp(`rel=${linkHeaderRel}`).test(linkItem)
    );
    if (linkItem == undefined) {
      return { page, perPage };
    }
    const urlstring = linkItem.split(';')[0].trim().slice(1, -1);
    const url = new URL(urlstring);

    Array.from(url.searchParams.entries()).forEach(
      (keyValue: [string, string]) => {
        const [key, value] = keyValue;
        if (key === 'page') page = parseInt(value) as number;
        if (key === 'per_page') perPage = parseInt(value) as number;
      }
    );
    return { page, perPage };
  }
  //
  // パラメータ取得
  //
  getPagerQueries() {
    return {
      page: this.currentPage,
      perPage: this.perPageCount,
    };
  }

  //
  // ページ移動
  //
  setPageToFirst() {
    this.currentPage = 1;
  }
  setPageToPrev() {
    if (this.isPrevAvailable()) {
      this.currentPage -= 1;
    }
  }
  setPageToNext() {
    if (this.isNextAvailable()) {
      this.currentPage += 1;
    }
  }
  setPageToLast() {
    this.currentPage = this.totalPage;
  }
  //
  // 有効無効
  //
  isFirstAvailable() {
    return this.currentPage != 1;
  }
  isPrevAvailable() {
    return this.currentPage !== 1;
  }
  isNextAvailable() {
    return this.currentPage < this.totalPage;
  }
  isLastAvailable() {
    return this.currentPage != this.totalPage;
  }
  //
  // ローディング
  //
  isLoading() {
    return this.totalCount === null;
  }
  //
  // 表示件数
  //
  countBegin() {
    return (this.currentPage - 1) * this.perPageCount + 1;
  }
  countEnd() {
    return this.currentPage * this.perPageCount;
  }
}

export type PagingParams = {
  page?: number;
  perPage?: number;
};
