import { defaultSettings } from './default';
import { localhost } from './localhost';
import { test } from './test';
import { development } from './development';
import { staging } from './staging';
import { production } from './production';

export const useSettings = () => {
  const env = import.meta.env.VITE_ENV || 'localhost';
  if (env === 'localhost') {
    return { settings: Object.assign(defaultSettings, localhost) };
  } else if (env === 'test') {
    return { settings: Object.assign(defaultSettings, test) };
  } else if (env === 'development') {
    return { settings: Object.assign(defaultSettings, development) };
  } else if (env === 'staging') {
    return { settings: Object.assign(defaultSettings, staging) };
  } else if (env === 'production') {
    return { settings: Object.assign(defaultSettings, production) };
  }
  return { settings: defaultSettings };
};
