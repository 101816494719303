import json from '@/api/apiMock/data_files/aws_securityhub_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/aws/securityhub/get.types';
import { AwsSecurityHubEventHistory } from '@/models/AwsSecurityHubEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      standard_ids:
        obj.data?.Compliance?.AssociatedStandards?.map(
          (standard: any) => standard.StandardsId
        ) || [],
      status: obj.data?.Compliance?.Status || '',
      severity: obj.data?.Severity?.Label || '',
      security_control_id: obj.data?.Compliance?.securityControlId || '',
      description: obj.data?.Description || '',
      event_time: obj.occurred_at || null,
    };
    return new AwsSecurityHubEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const awsSecurityHubEventHistories = events as AwsSecurityHubEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsSecurityHubEventHistories,
    pager,
  };
};
