import { AwsEc2InstanceStateEventHistory } from '@/models/AwsEc2InstanceStateEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/ec2/instance_state/index',
    queryParams
  );
  const awsEc2InstanceStateEventHistories: AwsEc2InstanceStateEventHistory[] =
    data.aws_ec2_events?.map(
      (awsEc2InstanceStateEventHistory: any) =>
        new AwsEc2InstanceStateEventHistory(awsEc2InstanceStateEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsEc2InstanceStateEventHistories,
    pager,
  };
};
