import { request } from '@/utils/api_client/sreshine';
import { IntegrationList } from '@/models/IntegrationList';

export const get = async (params: ParamsGetIntegrations) => {
  const { data } = await request.get(`/products/integrations/index`, params);
  const integrationList = new IntegrationList(data.integrations);
  return integrationList;
};

export type ParamsGetIntegrations = {
  organization: string;
  app_name: string;
};
