import { watch, onMounted } from 'vue';
import { useHtmlHead } from '@/models/global/htmlHead';
import { useRouter } from 'vue-router';

export const useHtmlMeta = () => {
  const { htmlHead, setTitle } = useHtmlHead();
  const router = useRouter();

  // 監視
  watch(router.currentRoute, (newValue, oldValue) => {
    // 検索条件の変更など、パラメータだけ変わった場合はタイトルを変更しない
    if (newValue.path === oldValue.path) return;
    setTitle('');
  });

  // HTMLのタイトルに反映させる
  const setHtmlHeadTitle = () => {
    if (htmlHead.title !== '') {
      document.title = htmlHead.title + '｜srest';
    } else {
      document.title = 'srest';
    }
  };
  onMounted(() => {
    setHtmlHeadTitle();
  });
  watch(htmlHead, () => {
    setHtmlHeadTitle();
  });

  return {
    setTitle,
  };
};
