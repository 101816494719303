import { request } from '@/utils/api_client/sreshine';

export const createCloudFormationTemplate = async (
  params: ParamsCreateIntegration
): Promise<any> => {
  return await request.post(
    `/products/integrations/aws_v2/costs/create_template`,
    {
      ...params,
    }
  );
};

export type ParamsCreateIntegration = {
  organization: string;
  app_name: string;
  role_arn: string;
};

export type CostInsightsChoices = {
  cost_explorer: boolean;
  data_exports: boolean;
};
