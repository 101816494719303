import { SentryEventHistory } from '@/models/SentryEventHistory';
import { QueryParams } from './get.types';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/sentry/index',
    queryParams
  );
  const sentryEventHistories: SentryEventHistory[] =
    data.sentry_events?.map(
      (sentryEventHistory: any) => new SentryEventHistory(sentryEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    sentryEventHistories,
    pager,
  };
};
