import { Task } from '@/models/Task';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/task_management/tasks',
    queryParams
  );
  data.tasks = data.tasks || [];
  const tasks: Task[] = data.tasks?.map((task: any) => new Task(task)) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    tasks,
    pager,
  };
};
