export class IntegrationAws {
  roleArn: string;
  externalId: string;
  enabled: boolean;
  accountId: string;
  constructor(data: any) {
    this.roleArn = data.role_arn;
    this.externalId = data.external_id;
    this.enabled = data.enabled;
    this.accountId = data.account_id;
  }
}
