import { DateWithZone } from '@/models/DateWithZone';
import { camelCaseLabel } from '@/utils/case_style';

export class EventHistory {
  eventId: string;
  appName: string;
  eventTime: DateWithZone | null;
  [key: string]: any;
  constructor(data: any) {
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.eventTime = new DateWithZone(data.event_time);
    Object.keys(data)
      .filter((key) => !['event_id', 'app_name', 'event_time'].includes(key))
      .forEach((key) => {
        this[camelCaseLabel(key)] = data[key];
      });
  }
}
