import { QueryParams } from '@/api/apiSreshine/aws/guardduty/get.types';
import { BaseChartPieData } from '@/assets/base_components';
import json from '@/api/apiMock/data_files/aws_guardduty_events.json';
import { getFilter, countAppNames } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { AwsGuardDutyEventHistory } from '@/models/AwsGuardDutyEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPieData = async (queryParams: QueryParams) => {
  const all = jsonData.data?.map((obj: any) => {
    return new AwsGuardDutyEventHistory(obj);
  });
  recent(all);
  const { events } = getFilter(all, queryParams);
  const header: any = [['name', 'count']];
  const data = countAppNames(events);

  return {
    data: header.concat(data) as BaseChartPieData,
  };
};
