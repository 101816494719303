import { request } from '@/utils/api_client/sreshine';

export const remove = async (params: ParamRemoveIntegration): Promise<any> => {
  return await request.post(`/products/integrations/datadog/delete`, params);
};

export type ParamRemoveIntegration = {
  organization: string;
  appName: string;
};
