import { QueryParams } from './get.types';
import { request } from '@/utils/api_client/sreshine';
import { BaseChartLineData } from '@/assets/base_components';

export const getTimelineData = async (queryParams: QueryParams) => {
  const { data, error } = await request.get(
    '/event/datadog/timeline',
    queryParams
  );
  if (error) {
    return { data: [] };
  }
  return {
    data: data as BaseChartLineData,
  };
};
