import { apiAmplify } from '@/api';

/*
 * ヘッダーを取得する。ログイン済みの場合は、トークンを付与する
 */
export const getHeaders = async () => {
  const { jwtToken, accessToken } = await getJwtToken();
  return {
    // API Gatewayで認証する際に使う
    Authorization: jwtToken,
    // BackendLambdaで認証する際に使う
    AccessToken: accessToken,
  };
};

/*
 * トークンを取得する。
 */
const getJwtToken = async () => {
  const { jwtToken, accessToken } = await apiAmplify.auth.getTokens();
  return { jwtToken, accessToken };
};
