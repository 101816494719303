import { DateWithZone } from '@/models/DateWithZone';

export class AwsHealthEventHistory {
  organization: string;
  appName: string;
  eventId: string;
  service: string;
  eventTypeCategory: string;
  description: string;
  affectedEntities: string;
  eventRegion: string;
  status: string;
  communicationId: string;
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.appName = data.app_name || '';
    this.eventId = data.event_id || '';
    this.service = data.service || '';
    this.eventTypeCategory = data.event_type_category || '';
    this.description = data.description || '';
    this.affectedEntities = data.affected_entities || '';
    this.eventRegion = data.event_region || '';
    this.status = data.status || '';
    this.communicationId = data.communication_id || '';
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || '';
  }
}
