import { AwsInspectorEc2PackageEventHistory } from '@/models/AwsInspectorEc2PackageEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/inspector/ec2_package/index',
    queryParams
  );
  const awsInspectorEc2PackageEventHistories: AwsInspectorEc2PackageEventHistory[] =
    data.aws_inspector_events?.map(
      (awsInspectorEc2PackageEventHistory: any) =>
        new AwsInspectorEc2PackageEventHistory(
          awsInspectorEc2PackageEventHistory
        )
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsInspectorEc2PackageEventHistories,
    pager,
  };
};
