import { User } from '@/models/User';
import { request } from '@/utils/api_client/sreshine';

export const get = async (organizationLabel: string) => {
  const { data } = await request.get('/organizations/users/index', {
    organizationLabel,
  });
  const users: User[] = data.users?.map((user: any) => new User(user)) || [];
  return { users };
};
