import { AwsCloudwatchEventHistory } from '@/models/AwsCloudwatchEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/cloudwatch/index',
    queryParams
  );
  const awsCloudwatchEventHistories: AwsCloudwatchEventHistory[] =
    data.aws_cloudwatch_events?.map(
      (awsCloudwatchEventHistory: any) =>
        new AwsCloudwatchEventHistory(awsCloudwatchEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsCloudwatchEventHistories,
    pager,
  };
};
