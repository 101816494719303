import { reactive, readonly } from 'vue';

// 型定義
interface SnackbarMessage {
  message: string;
  type: '' | 'success' | 'error';
}

const snackbarMessage = reactive<SnackbarMessage>({ message: '', type: '' });

export const useSnackbarMessage = () => {
  const setSnackbarMessage = (data: {
    message: string;
    type?: '' | 'success' | 'error';
  }) => {
    snackbarMessage.message = data.message || '';
    snackbarMessage.type = data.type || 'error';
  };
  return {
    snackbarMessage: readonly(snackbarMessage),
    setSnackbarMessage,
  };
};
