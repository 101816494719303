import { SearchParams } from '@/api/apiSreshine/cost/summary.types';
import { DateWithZone } from '@/models/DateWithZone';

export const details = async (searchParams: SearchParams) => {
  //
  // durationの計算
  //
  // current
  const currentStart = searchParams.startAt.split('T')[0];
  const currentEnd = searchParams.endAt.split('T')[0];
  // old
  const tempStart = new Date(searchParams.startAt);
  const tempEnd = new Date(searchParams.endAt);
  const diff = tempEnd.getTime() - tempStart.getTime();
  const oneDay = 86400000;
  const oldStartEpoch = tempStart.setTime(tempStart.getTime() - diff - oneDay);
  const oldEndEpoch = tempEnd.setTime(tempEnd.getTime() - diff - oneDay);
  const oldStart = new DateWithZone(oldStartEpoch).toISOString().split('T')[0];
  const oldEnd = new DateWithZone(oldEndEpoch).toISOString().split('T')[0];

  //
  // dataの用意
  //
  const data = {
    dataStrings: ['2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16'],
    durationStrings: searchParams.isCompare
      ? [`${currentStart} - ${currentEnd}`, `${oldStart} - ${oldEnd}`]
      : [`${currentStart} - ${currentEnd}`],
    data: [] as { name: string; values: number[] }[],
    oldData: searchParams.isCompare
      ? ([] as { name: string; values: number[] }[])
      : undefined,
  };
  if (searchParams.services.includes('Product A')) {
    data.data.push({ name: 'Product A', values: [120, 122, 132, 123] });
    if (searchParams.isCompare && data.oldData) {
      data.oldData.push({ name: 'Product A', values: [110, 112, 122, 113] });
    }
  }
  if (searchParams.services.includes('Product B')) {
    data.data.push({ name: 'Product B', values: [100, 102, 112, 103] });
    if (searchParams.isCompare && data.oldData) {
      data.oldData.push({ name: 'Product B', values: [90, 102, 122, 123] });
    }
  }
  if (searchParams.services.includes('Product C')) {
    data.data.push({ name: 'Product C', values: [110, 112, 122, 113] });
    if (searchParams.isCompare && data.oldData) {
      data.oldData.push({ name: 'Product C', values: [80, 132, 122, 93] });
    }
  }
  return {
    data,
  };
};
