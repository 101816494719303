export const production = {
  host: 'https://api.dashboard.srest.io',
  env: 'production',
  sentry: {
    dsn: 'https://db93037f06224f0580a6fd6f8f5b3686@o1172945.ingest.sentry.io/4504132874469376',
  },
  amplify: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_Qos6veGlb',
    userPoolWebClientId: '5ei531q55dk4s786pf02s5o868',
  },
};
