import { Organization } from '@/models/Organization';

export const appNames = async () => {
  const data = {
    organizations: [
      {
        label: 'metaps',
        name: 'Metaps Demo',
        app_names: [
          { label: 'Product A', name: 'Product A' },
          { label: 'Product B', name: 'Product B' },
          { label: 'Product C', name: 'Product C' },
          { label: 'Product D', name: 'Product D' },
          { label: 'demo', name: 'Product E' },
        ],
      },
    ],
  };
  const organizations: Organization[] = data.organizations.map(
    (organization: any) => new Organization(organization)
  );
  return { organizations };
};
