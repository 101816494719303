import { request } from '@/utils/api_client/sreshine';

export const createCloudFormationStackSetsTemplate = async (
  params: ParamsCreateStackSetsTemplate
): Promise<any> => {
  return await request.post(
    `/products/integrations/aws_v2/events/create_template`,
    {
      ...params,
    }
  );
};

export type ParamsCreateStackSetsTemplate = {
  organization: string;
  app_name: string;
  services: string[];
};
