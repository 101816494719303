import { Auth } from 'aws-amplify';
import { CurrentUser } from '@/models/global/currentUser';
/**
 * ログイン中のユーザー情報を取得する
 * @returns ログイン中のユーザー情報 (ログインしていなければ空文字)
 */
export const currentUser = async () => {
  try {
    const session = await Auth.currentAuthenticatedUser({ bypassCache: true }); // bypassCache: 最新情報を取得するにはtrueが必要です。
    const currentUser = {
      username: session.attributes.preferred_username
        ? session.attributes.preferred_username
        : session.username,
      email: session.attributes.email,
      imageUrl: session.attributes.picture,
      timezone: session.attributes.zoneinfo,
    } as CurrentUser;
    return currentUser;
  } catch (error) {
    return null;
  }
};
