import { request } from '@/utils/api_client/sreshine';

export const createIntegration = async (
  params: ParamsCreateIntegration
): Promise<any> => {
  return await request.post(`/products/integrations/aws/integrate`, {
    ...params,
  });
};

export type ParamsCreateIntegration = {
  organization: string;
  app_name: string;
  role_arn: string;
};
