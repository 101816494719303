import localeColumns from '@/config/locales/columns/ja.json';
import localeResponses from '@/config/locales/responses/ja.json';
import localeEnums from '@/config/locales/enums/ja.json';
import { camelCaseLabel } from '@/utils/case_style';
import { CurrentUser } from '@/models/global/currentUser';

export const enums = localeEnums['ja'].enums;

/**
 * i18nColumn カラム名を表示する
 * @param name model-columnの書式で指定する (例: create_corporation_user_form-name)
 */
export const i18nColumn = (name: string) => {
  const columns = localeColumns['ja'].attributes as any;
  const defautName = columns.default;
  const names = name.split('-');
  if (names.length != 2) {
    return defautName;
  }
  const nameModel = names[0];
  const nameColumn = names[1];
  const model = columns[nameModel];
  if (!model) {
    return nameColumn;
  }
  const column = model[nameColumn];
  if (column) {
    return column;
  } else {
    return nameColumn;
  }
};

/**
 * サーバレスポンスで表示するメッセージを表示する
 * @param rule jsonで定義したパスを指定する (例: system.render500)
 * @param name {name}がメッセージに含まれる場合、model-columnの書式で指定するカラム名で置換する (例: create_corporation_user_form-name)
 */
export const i18nResponse = (rule: string, name = '', count?: string) => {
  const notices = localeResponses['ja'].messages as any;
  const keys = rule.split('.');
  let value = notices;
  for (const key of keys) {
    value = value[key];
  }
  let message = value;
  if (!message) {
    message = notices.messages.system.default;
  }
  const columnName = i18nColumn(name);
  return message.replaceAll('{name}', columnName).replace('{count}', count);
};

// i18nEnum Enumの値を表示する。i18nColumn('project-scope')のように、model-columnを入れて使用する。

/**
 * Enumの値を表示する
 * @param name model-columnの書式で指定する (例: create_corporation_user_form-name)
 * @param arg Enumの値を入れる
 */
export const i18nEnum = (name: string, arg: string) => {
  const enums = localeEnums['ja'].enums as any;
  const names = name.split('-');
  if (names.length != 2) {
    return '';
  }
  const nameModel = names[0];
  const nameColumn = names[1];
  let value;
  if (typeof arg === 'object') {
    const camelNameModel = camelCaseLabel(nameModel);
    const camelNameColumn = camelCaseLabel(nameColumn);
    // propsの場合
    if (arg[camelNameModel]) {
      const props = arg;
      value = props[camelNameModel][camelNameColumn];
    } else {
      const reactiveValue = arg;
      value = reactiveValue[camelNameColumn];
    }
  } else {
    value = arg;
  }
  const model = enums[nameModel];
  if (!model) {
    return value;
  }
  const column = model[nameColumn];
  if (!column) {
    return value;
  }
  const enumName = column[value];
  if (enumName) {
    return enumName;
  } else {
    return value;
  }
};

/**
 * SelectのOptionsの値を用意する
 * @param name model-columnの書式で指定する (例: create_corporation_user_form-name)
 */
export const i18nEnumOptions = (name: string) => {
  const enums = localeEnums['ja'].enums as any;
  const names = name.split('-');
  if (names.length != 2) {
    return '';
  }
  const nameModel = names[0];
  const nameColumn = names[1];
  const model = enums[nameModel];
  if (!model) {
    return [];
  }
  const columns = model[nameColumn];
  return columns;
};

/**
 * サービス名を表示する
 */
export const i18nAppName = (appNameLabel: string, currentUser: CurrentUser) => {
  for (const organization of currentUser.organizations) {
    for (const appName of organization.appNames) {
      if (appName.label === appNameLabel) {
        return appName.name;
      }
    }
  }
  return appNameLabel;
};
