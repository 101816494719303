import { ParamsCreateNotificationChannel } from '@/api/apiSreshine/product/notification/createNotificationChannels';

/* eslint-disable */
export const createNoticiationChannels = async (
  oganization: string,
  appName: string,
  params: ParamsCreateNotificationChannel[],
): Promise<any> => {
  return true;
};
/* eslint-enable */
