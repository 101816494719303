import { DateWithZone } from '@/models/DateWithZone';

export class Notice {
  description: string;
  timeStamp: DateWithZone;

  constructor(data: any) {
    this.description = data.description || '';
    this.timeStamp = new DateWithZone(data.time_stamp);
  }
}
