import { QueryParams } from '@/api/apiSreshine/newrelic/get.types';
import { BaseChartPieData } from '@/assets/base_components';
import json from '@/api/apiMock/data_files/newrelic_events.json';
import { getFilter, countAppNames } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { NewRelicEventHistory } from '@/models/NewRelicEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPieData = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      service_name: obj.project,
      title: obj.title,
      url: obj.detail?.issueUrl,
      priority: obj.detail?.priority,
      status: obj.detail?.state,
      event_time: obj.time,
    };
    return new NewRelicEventHistory(json);
  });
  recent(all);
  const { events } = getFilter(all, queryParams);
  const header: any = [['name', 'count']];
  const data = countAppNames(events);
  return {
    data: header.concat(data) as BaseChartPieData,
  };
};
