import axios from 'axios';
import { getHeaders } from './header';
import { getUrl } from './url';
import { parseResponse, parseError } from './response';
import { urlQuery, normalizeParams } from './params';
import { useFormErrors } from '@/models/global/formErrors';

let localSetFormErrors: (errors: { id: string; message: string }[]) => void;

export const request = {
  async get<T>(path: string, params?: T) {
    localSetFormErrors([]);
    if (params) {
      path = `${path}?${urlQuery(params)}`;
    }
    const headers = await getHeaders();
    const url = getUrl(path);
    try {
      const res = await axios.get(url, { headers: headers });
      return parseResponse(res);
    } catch (error) {
      return parseError(error, localSetFormErrors);
    }
  },
  async put<T>(path: string, params?: T) {
    localSetFormErrors([]);
    const headers = await getHeaders();
    const url = getUrl(path);
    try {
      const res = await axios.put(url, normalizeParams(params), {
        headers: headers,
      });
      return parseResponse(res);
    } catch (error) {
      return parseError(error, localSetFormErrors);
    }
  },
  async post<T>(path: string, params?: T) {
    localSetFormErrors([]);
    const headers = await getHeaders();
    const url = getUrl(path);
    try {
      const res = await axios.post(url, normalizeParams(params), {
        headers: headers,
      });
      return parseResponse(res);
    } catch (error) {
      return parseError(error, localSetFormErrors);
    }
  },
  async delete<T>(path: string, params?: T) {
    localSetFormErrors([]);
    const headers = await getHeaders();
    if (params) {
      path = `${path}?${urlQuery(params)}`;
    }
    const url = getUrl(path);
    try {
      const res = await axios.delete(url, { headers: headers });
      return parseResponse(res);
    } catch (error) {
      return parseError(error, localSetFormErrors);
    }
  },
  inject() {
    const { setFormErrors } = useFormErrors();
    localSetFormErrors = setFormErrors;
  },
};
