<script lang="ts" setup>
import { BaseSnackbar } from './assets/base_components/';
import { useHtmlMeta } from '@/hooks/utils/useHtmlMeta';
import { useAuthentication } from '@/hooks/auth/useAuthentication';
import { request } from '@/utils/api_client/sreshine';
const { isLoadingAuthState } = useAuthentication();

request.inject();
// routerの切り替えでhtmlのheadを更新する処理を登録
useHtmlMeta();
</script>
<template>
  <v-app>
    <template v-if="!isLoadingAuthState">
      <router-view />
    </template>
    <BaseSnackbar />
  </v-app>
</template>
