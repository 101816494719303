import { request } from '@/utils/api_client/sreshine';

export const create = async (
  organization: string,
  appName: string,
  params: ParamsCreateApiKey
) => {
  return await request.post(`/products/product_api_keys/create`, {
    organization,
    app_name: appName,
    ...params,
  });
};

export type ParamsCreateApiKey = {
  name: string;
  description: string;
};
