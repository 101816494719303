import { apiAmplify, apiMock, apiSreshine } from '@/api';
import { onBeforeMount, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useCurrentUser } from '@/models/global/currentUser';

// ログイン監視
export const useAuthentication = () => {
  const isLoadingAuthState = ref(true);
  const { currentUser, setCurrentUser, setOrganizations, clearCurrentUser } =
    useCurrentUser();

  // ログイン状態で、未ログイン専用ページを見ている場合は、`/`へリダイレクトする。
  // 未ログイン状態で、ログイン専用ページを見ている場合は、`/auth/sign_in`へリダイレクトする。
  const router = useRouter();
  const checkAuthentication = async () => {
    // currentUserはログインしていればユーザー名が入っている
    // routerのcurrentRoute.value.pathはreactiveで遅延があるので、location.pathnameを使う。
    const user = await apiAmplify.auth.currentUser();
    const currentPath = location.pathname;
    if (user) {
      //
      // ログイン中の場合
      //
      setCurrentUser(user);
      if (currentUser.value.organizations.length === 0) {
        const api = currentUser.value.isTester ? apiMock : apiSreshine;
        const { organizations } = await api.user.appNames();
        setOrganizations(organizations);
      }
      if (unauthenticatedOnly.includes(currentPath)) {
        // 表示をブロックしてリダイレクトする。
        isLoadingAuthState.value = true;
        setTimeout(() => {
          // URL直接アクセス対策。URL直接アクセスした場合は、router.pushしてもrouterのwatchが走らないので、手動でLoadingを落とす。
          isLoadingAuthState.value = false;
        }, 200);
        router.push('/');
        return;
      }
    } else {
      //
      // 未ログインの場合
      //
      clearCurrentUser();
      if (!unauthenticatedAllowed.includes(currentPath)) {
        isLoadingAuthState.value = true;
        setTimeout(() => {
          isLoadingAuthState.value = false;
        }, 200);
        router.push('/auth/sign_in');
        return;
      }
    }
    isLoadingAuthState.value = false;
  };

  // タイミング: (1) 初回ページ読み込み。(2) HMR
  onBeforeMount(() => {
    checkAuthentication();
  });
  // タイミング: (1) RouterLinkでページ移動。(2) Root(/)以外での初回ページ読み込みのリダイレクト
  // 補足: 例えば/dashboardにURLで直接アクセスしたとき、currentRoute.value.pathは「/」になったあと「/dashboard」になる。そのため、routerのwatchが走る。
  watch(
    () => router.currentRoute.value.path,
    () => {
      // 初回ページ読み込みのリダイレクトでは実行しない
      if (isLoadingAuthState.value) {
        return;
      }
      checkAuthentication();
    },
  );
  return { isLoadingAuthState };
};

// 未ログイン専用ページ (ログイン画面など)
const unauthenticatedOnly = ['/auth/sign_in', '/auth/sign_up'];

// 未ログイン許可ページ (ログイン画面や、規約など)
const unauthenticatedAllowed = [
  '/auth/sign_in',
  '/auth/sign_up',
  '/auth/forgot_password',
  '/auth/reset_password',
];
