import { DateWithZone } from '@/models/DateWithZone';
import { DateTime } from 'luxon';

/**
 * 文字列をキャメルケースに変換する。
 */
export const camelCaseLabel = (str: string) => {
  str = str.charAt(0).toLowerCase() + str.slice(1);
  return str.replace(/[-_](.)/g, (match: any, group: any) => {
    return group.toUpperCase();
  });
};

/**
 * 文字列をスネークケースに変換する
 */
export const snakeCaseLabel = (str: string): string => {
  return str.replace(/[A-Z]/g, (s) => {
    return '_' + s.charAt(0).toLowerCase();
  });
};

/**
 * オブジェクトのキーをスネークケースに変換する
 */
export const snakeCases = (params: any) => {
  if (params === undefined || params === null) {
    return {};
  }
  const jsonParams = convertKeyToSnakeCase(params);
  return jsonParams;
};

const convertKeyToSnakeCase = (params: any) => {
  const jsonParams: any = {};
  for (const [key, v] of Object.entries(params)) {
    const value = v as any;
    if (v === undefined || v === null || v === '') {
      continue;
    } else if (Array.isArray(value)) {
      jsonParams[snakeCaseLabel(key)] = value;
    } else if (value.constructor === DateWithZone) {
      // サーバ側が+9:00表記だとエラーになってしまうので、UTC表記に変換する
      const date = DateTime.fromISO(value.toISOString(), {
        zone: 'UTC',
      });
      jsonParams[snakeCaseLabel(key)] = date.toISO();
    } else if (value.constructor.name === 'File') {
      // undefined.undefinedとつなげてもエラーにはならない
      jsonParams[snakeCaseLabel(key)] = value;
    } else if (value.constructor == Date) {
      jsonParams[snakeCaseLabel(key)] = value.toISOString();
    } else if (typeof value === 'object') {
      const childValue = convertKeyToSnakeCase(value);
      jsonParams[snakeCaseLabel(key)] = childValue;
    } else {
      jsonParams[snakeCaseLabel(key)] = value;
    }
  }
  return jsonParams;
};

/**
 * オブジェクトのキーをキャメルケースに変換する
 */
export const camelCases = (params: any) => {
  if (params === undefined || params === null) {
    return {};
  }
  const jsonParams = convertKeyToCamelCase(params);
  return jsonParams;
};

const convertKeyToCamelCase = (params: any) => {
  const jsonParams: any = {};
  for (const [key, v] of Object.entries(params)) {
    const value = v as any;
    if (v === undefined || v === null || v === '') {
      continue;
    } else if (Array.isArray(value)) {
      jsonParams[camelCaseLabel(key)] = value;
    } else if (value.constructor.name === 'File') {
      // undefined.undefinedとつなげてもエラーにはならない
      jsonParams[camelCaseLabel(key)] = value;
    } else if (value.constructor == Date) {
      jsonParams[camelCaseLabel(key)] = value.toISOString();
    } else if (typeof value === 'object') {
      const childValue = convertKeyToCamelCase(value);
      jsonParams[camelCaseLabel(key)] = childValue;
    } else {
      jsonParams[camelCaseLabel(key)] = value;
    }
  }
  return jsonParams;
};
