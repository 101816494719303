import { QueryParams } from '@/api/apiSreshine/aws/ecs/get.types';
import { BaseChartPieData } from '@/assets/base_components';
import json from '@/api/apiMock/data_files/aws_ecs_events.json';
import { getFilter, countAppNames } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { AwsEcsEventHistory } from '@/models/AwsEcsEventHistory';

const jsonData = JSON.parse(JSON.stringify(json));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPieData = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      cluster_name: obj.data?.clusterArn
        ? obj.data?.clusterArn.split('/')[1]
        : '',
      stopped_reason: obj.data?.stoppedReason || '',
      group_name: obj.data?.group || '',
      task_definition: obj.data?.taskDefinitionArn
        ? obj.data.taskDefinitionArn.split('/')[1]
        : '',
      revision: obj.data?.taskDefinitionArn
        ? obj.data?.taskDefinitionArn.split(':').pop()
        : '',
      status: obj.data?.lastStatus || '',
      event_time: obj.data?.updatedAt || null,
    };
    return new AwsEcsEventHistory(json);
  });
  recent(all);
  const { events } = getFilter(all, queryParams);
  const header: any = [['name', 'count']];
  const data = countAppNames(events);
  return {
    data: header.concat(data) as BaseChartPieData,
  };
};
