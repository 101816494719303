import json from '@/api/apiMock/data_files/aws_inspector_ec2_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/aws/inspector/ec2Network/get.types';
import { AwsInspectorEc2NetworkEventHistory } from '@/models/AwsInspectorEc2NetworkEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      description: obj.description || '',
      severity: obj.severity || '',
      event_time: obj.event_time || null,
    };
    return new AwsInspectorEc2NetworkEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const awsInspectorEc2NetworkEventHistories =
    events as AwsInspectorEc2NetworkEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsInspectorEc2NetworkEventHistories,
    pager,
  };
};
