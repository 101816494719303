import { QueryParams } from '@/api/apiSreshine/aws/inspector/ec2Network/get.types';
import { BaseChartPieData } from '@/assets/base_components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPieData = async (queryParams: QueryParams) => {
  const data: any = [['AppName', 'count']];
  const appNames = ['Product A', 'Product B'];
  appNames.forEach((appName) => {
    data.push([appName, Math.floor(Math.random() * 10)]);
  });
  return {
    data: data as BaseChartPieData,
  };
};
