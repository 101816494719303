import { request } from '@/utils/api_client/sreshine';
import { ApiKey } from '@/models/ApiKey';

export const get = async (params: ParamsGetApiKeys) => {
  const { data } = await request.get(
    `/products/product_api_keys/index`,
    params
  );
  const apiKeys: ApiKey[] =
    data.product_api_keys?.map((apiKey: any) => new ApiKey(apiKey)) || [];

  return apiKeys;
};

export type ParamsGetApiKeys = {
  organization: string;
  appName: string;
};
