import { ParamsCreateNotificationMonitor } from '@/api/apiSreshine/product/notification/createNotificationMonitor';

/* eslint-disable */
export const createNotificationMonitor = async (
  organization: string,
  app_name: string,
  params: ParamsCreateNotificationMonitor,
): Promise<any> => {
  return true;
};
/* eslint-enable */
