import { Auth } from 'aws-amplify';
/**
 * JwtTokenを取得する
 * @returns トークン (ログインしていなければ空文字)
 */
export const getTokens = async () => {
  try {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    return { jwtToken, accessToken };
  } catch (error) {
    return { jwtToken: '', accessToken: '' };
  }
};
