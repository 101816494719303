import { UserProductPermission } from './UserProductPermission';
import { DateWithZone } from '@/models/DateWithZone';

export class User {
  username: string;
  email: string;
  imageUrl: string;
  userProductPermissions: UserProductPermission[];
  createdAt: DateWithZone | null;

  constructor(data: any) {
    this.username = data.username || '';
    this.email = data.email || '';
    this.imageUrl = data.image_url || '';
    this.userProductPermissions =
      data.user_product_permissions?.map(
        (userProductPermission: any) =>
          new UserProductPermission(userProductPermission)
      ) || [];
    this.createdAt = data.created_at ? new DateWithZone(data.created_at) : null;
  }
}
