import { ParamsCertifyEmail } from '@/api/apiSreshine/product/notification/certifyEmail';

/* eslint-disable */
export const certifyEmail = async (
  organization: string,
  appName: string,
  params: ParamsCertifyEmail,
): Promise<any> => {
  return true;
};
/* eslint-enable */
