import { DateWithZone } from '@/models/DateWithZone';

export class AwsEc2SpotInstanceInterruptionEventHistory {
  organization: string;
  eventId: string;
  appName: string;
  instanceId: string;
  action: string;
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.instanceId = data.instance_id || '';
    this.action = data.action || '';
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || '';
  }
}
