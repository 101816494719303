import { DateWithZone } from '@/models/DateWithZone';

/* eslint-disable */
export const getSelectableChannels = async (
  organization: string,
  app_name: string,
  metrics: string | null,
): Promise<
  {
    id: string;
    name: string;
    type: 'slack' | 'email';
    webhookUrl: string | null;
    email: string | null;
    createdAt: DateWithZone;
    selected: boolean;
  }[]
> => {
  const data = [
    {
      id: '1',
      name: 'test',
      type: 'slack' as 'slack' | 'email',
      webhookUrl: 'https://hooks.slack.com/services/xxxxx',
      email: null,
      createdAt: new DateWithZone('2021-01-01T00:00:00+09:00'),
      selected: true,
    },
  ];

  return data;
};
/* eslint-enable */
