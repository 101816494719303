import { request } from '@/utils/api_client/sreshine';

export const createExternalId = async (
  params: ParamsCreateExternalId
): Promise<any> => {
  return await request.post(`/products/integrations/aws_v2/settings/create`, {
    ...params,
  });
};

export type ParamsCreateExternalId = {
  organization: string;
  app_name: string;
  external_id: string;
  account_id: string;
};
