import { DateWithZone } from '@/models/DateWithZone';

export class AwsGuardDutyEventHistory {
  organization: string;
  eventId: string;
  appName: string;
  type: string;
  title: string;
  description: string;
  severity: string;
  eventTime: DateWithZone | null;
  json: string;

  constructor(data: any) {
    this.organization = data.organization || '';
    this.eventId = data.event_id || '';
    this.appName = data.app_name || '';
    this.type = data.type || '';
    this.title = data.title || '';
    this.description = data.description || '';
    this.severity = data.severity || '';
    this.eventTime = data.event_time ? new DateWithZone(data.event_time) : null;
    this.json = data.json || undefined;
  }
}
