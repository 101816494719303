import { request } from '@/utils/api_client/sreshine';

export const createIntegtaion = async (
  params: ParamsCreateIntegration
): Promise<any> => {
  return await request.post(`/products/integrations/new_relic/create`, {
    ...params,
  });
};

export type ParamsCreateIntegration = {
  organization: string;
  app_name: string;
  api_key: string;
  account_id: string;
};
