import json from '@/api/apiMock/data_files/sentry_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/sentry/get.types';
import { SentryEventHistory } from '@/models/SentryEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      project_name: obj.project,
      title: obj.data?.data?.event?.title,
      url: obj.data?.data?.event?.web_url,
      transaction: obj.data?.data?.event?.location,
      environment: obj.data?.data?.event?.environment,
      level: obj.data?.data?.event?.level,
      event_time: obj.data?.data?.event?.datetime,
    };
    return new SentryEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const sentryEventHistories = events as SentryEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);

  return {
    sentryEventHistories,
    pager,
  };
};
