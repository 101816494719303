import { request } from '@/utils/api_client/sreshine/';
import { Base64 } from './post.types';

interface Params {
  image: Base64<'png' | 'jpeg'>;
  filename: string;
}

export const post = async (params: Params) => {
  await request.post('/user/image_upload', params);
};
