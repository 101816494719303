import { ParamsUpdateNotificationMonitor } from '@/api/apiSreshine/product/notification/updateNotificationMonitor';

/* eslint-disable */
export const updateNotificationMonitor = async (
  organization: string,
  app_name: string,
  monitors: ParamsUpdateNotificationMonitor[],
): Promise<any> => {
  return true;
};
/* eslint-enable */
