import { Organization } from '@/models/Organization';
import { request } from '@/utils/api_client/sreshine/';

export const appNames = async () => {
  const { data } = await request.get('/user/app_names');
  const organizations: Organization[] =
    data.organizations?.map(
      (organization: any) => new Organization(organization)
    ) || [];
  return { organizations };
};
