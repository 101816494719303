import { OncallMessage } from '@/models/OncallMessage';
import { Pager } from '@/models/Pager';
import json from '@/api/apiMock/data_files/oncall_messages.json';
const jsonData = JSON.parse(JSON.stringify(json));

export const get = async () => {
  const allModels: OncallMessage[] = jsonData.data?.map((obj: any) => {
    const json = {
      title: obj.title,
      description: obj.description,
      time_stamp: obj.time_stamp,
    };
    return new OncallMessage(json);
  });

  const oncallMessages = allModels as OncallMessage[];

  const pager = new Pager();
  pager.totalCount = 3;

  return {
    oncallMessages,
    pager,
  };
};
