import { EventHistory } from './EventHistory';
import { DateWithZone } from './DateWithZone';

export class Task {
  id: string | undefined;
  title: string;
  priority: Priority;
  status: Status;
  assignees: string[];
  dueDate: DateWithZone | undefined;
  description: string;
  organization: string;
  events: EventHistory[];
  eventIds: string[];

  constructor(data: any) {
    this.id = data._id.$oid;
    this.title = data.title || '';
    this.priority = data.priority || '';
    this.status = data.status || '';
    this.assignees = data.assignees
      ? data.assignees.map((assignee: string) => assignee)
      : [];
    this.dueDate = data.due_date ? new DateWithZone(data.due_date) : undefined;
    this.description = data.description || '';
    this.organization = data.organization || undefined;
    this.events = data.events
      ? data.events.map((event: any) => new EventHistory(event))
      : [];
    this.eventIds = data.event_ids || [];
  }
}

export type Priority = 'HIGH' | 'MEDIUM' | 'LOW' | '';
export type Status = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'PENDING' | '';

export const priorityOptions: { value: string; text: string }[] = [
  { value: 'HIGH', text: '高い' },
  { value: 'MEDIUM', text: '普通' },
  { value: 'LOW', text: '低い' },
];

export const statusOptions: { value: string; text: string }[] = [
  { value: 'NOT_STARTED', text: '未着手' },
  { value: 'IN_PROGRESS', text: '進行中' },
  { value: 'DONE', text: '完了' },
  { value: 'PENDING', text: '保留' },
];
