import { request } from '@/utils/api_client/sreshine';
import {
  DetailQueryParams,
  EventClassType,
  EventClassConstructor,
} from './get.types';

export const get = async <T extends InstanceType<EventClassType>>(
  queryParams: DetailQueryParams,
  eventClassConstructor: EventClassConstructor<any>
) => {
  const { data } = await request.get('/event/detail/show', queryParams);
  const model: T = new eventClassConstructor(data);

  return model;
};
