import { request } from '@/utils/api_client/sreshine';

export const createNotificationMonitor = async (
  organization: string,
  appName: string,
  params: ParamsCreateNotificationMonitor
): Promise<any> => {
  return await request.post(`/products/notifications/monitors/create`, {
    organization: organization,
    app_name: appName,
    metrics: params.metrics,
    channel_ids: params.channel_ids,
  });
};

export type ParamsCreateNotificationMonitor = {
  metrics: string;
  channel_ids: string[];
};
