import { request } from '@/utils/api_client/sreshine';

export const deleteIntegration = async (
  params: ParamDeleteIntegration
): Promise<any> => {
  return await request.delete(`/products/integrations/aws_v2/settings/delete`, {
    ...params,
  });
};

export type ParamDeleteIntegration = {
  organization: string;
  app_name: string;
};
