import { DateWithZone } from '@/models/DateWithZone';
import { NotificationMonitor } from '@/models/NotificationMonitor';
import { ParamsGetNotificationMonitors } from '@/api/apiSreshine/product/notification/getNotificationMonitors';

/* eslint-disable */
export const getNotificationMonitors = async (
  params: ParamsGetNotificationMonitors,
): Promise<NotificationMonitor[]> => {
  const data = [
    new NotificationMonitor({
      metrics: { key: 'aws.ecs.task_failure', name: 'ECSタスク異常終了', service: 'Amazon ECS' },
      channels: [
        {
          id: 'ijio70JPIJIjpa',
          name: 'Slack 1',
          type: 'stack',
          webhookUrl:
            'https://hooks.slack.com/services/xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          to: null,
          createdAt: new DateWithZone(),
        },
      ],
      status: 'active',
      createdAt: new DateWithZone(),
    }),
  ];

  return data;
};
/* eslint-enable */
