import { request } from '@/utils/api_client/sreshine';
import { NotificationMonitor } from '@/models/NotificationMonitor';

export const getNotificationMonitors = async (
  params: ParamsGetNotificationMonitors
): Promise<NotificationMonitor[]> => {
  const { data } = await request.get(
    `/products/notifications/monitors/index`,
    params
  );

  const notificationMonitors: NotificationMonitor[] =
    data.monitors?.map((monitor: any) => new NotificationMonitor(monitor)) ||
    [];

  return notificationMonitors;
};

export type ParamsGetNotificationMonitors = {
  organization: string;
  app_name: string;
};
