import { ref, Ref, inject, readonly } from 'vue';
import { DateWithZone } from '@/models/DateWithZone';

// 型定義
export interface CurrentHeaderFilter {
  organization?: string;
  appName?: string;
  startTime?: DateWithZone;
  endTime?: DateWithZone;
}

// 初期値 (URLにパラメータがあればそれらをセットする)
const parseQueryFromWindow = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const endTime = params.get('end_time')
    ? new DateWithZone(decodeURIComponent(params.get('end_time') as string))
    : new DateWithZone();
  const startTime = params.get('start_time')
    ? new DateWithZone(decodeURIComponent(params.get('start_time') as string))
    : new DateWithZone(endTime.getTime() - 7 * 3600 * 24 * 1000);

  return {
    organization: params.get('organization') || undefined,
    appName: params.get('app_name') || undefined,
    service: params.get('service') as string,
    startTime: startTime,
    endTime: endTime,
  };
};

// Provider
export const createCurrentHeaderFilter = () => {
  const currentHeaderFilter: Ref<CurrentHeaderFilter> = ref(
    parseQueryFromWindow()
  );
  return {
    currentHeaderFilter,
  };
};

// Inject
export const keyCurrentHeaderFilter = Symbol();
export const useCurrentHeaderFilter = () => {
  const { currentHeaderFilter } = inject(keyCurrentHeaderFilter) as {
    currentHeaderFilter: Ref<CurrentHeaderFilter>;
  };
  const setCurrentHeaderFilter = (
    _currentHeaderFilter: CurrentHeaderFilter
  ) => {
    const tempCurrentHeaderFilter = {
      ...currentHeaderFilter.value,
      ..._currentHeaderFilter,
    };
    if (!tempCurrentHeaderFilter.endTime) {
      tempCurrentHeaderFilter.endTime = new DateWithZone();
    }
    if (!tempCurrentHeaderFilter.startTime) {
      tempCurrentHeaderFilter.startTime = new DateWithZone(
        tempCurrentHeaderFilter.endTime.getTime() - 7 * 3600 * 24 * 1000
      );
    }
    currentHeaderFilter.value = tempCurrentHeaderFilter;
  };
  return {
    currentHeaderFilter: readonly(currentHeaderFilter),
    setCurrentHeaderFilter,
  };
};
