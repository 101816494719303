import { Task } from '@/models/Task';
import { Pager } from '@/models/Pager';
import type { QueryParams } from '@/api/apiSreshine/task/get.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  const tasks = [] as Task[];
  const pager = new Pager();

  return {
    tasks,
    pager,
  };
};
