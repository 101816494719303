import json from '@/api/apiMock/data_files/aws_guardduty_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/aws/guardduty/get.types';
import { AwsGuardDutyEventHistory } from '@/models/AwsGuardDutyEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  const all = jsonData.data?.map((obj: any) => {
    return new AwsGuardDutyEventHistory(obj);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const awsGuardDutyEventHistories = events as AwsGuardDutyEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsGuardDutyEventHistories,
    pager,
  };
};
