import { reactive, inject, readonly } from 'vue';

// 型定義
export interface HtmlHead {
  title: string;
}

// Provider
export const createHtmlHead = () => {
  const htmlHead = reactive<HtmlHead>({
    title: '',
  });
  return {
    htmlHead,
  };
};

// Inject
export const keyHtmlHead = Symbol();
export const useHtmlHead = () => {
  const { htmlHead } = inject(keyHtmlHead) as { htmlHead: HtmlHead };
  const setTitle = (title: string) => {
    htmlHead.title = title || '';
  };
  return {
    htmlHead: readonly(htmlHead),
    setTitle,
  };
};
