import json from '@/api/apiMock/data_files/newrelic_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/newrelic/get.types';
import { NewRelicEventHistory } from '@/models/NewRelicEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      service_name: obj.project,
      title: obj.detail?.title,
      url: obj.detail?.issueUrl,
      priority: obj.detail?.priority,
      status: obj.detail?.state,
      event_time: obj.time,
    };
    return new NewRelicEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const newRelicEventHistories = events as NewRelicEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);

  return {
    newRelicEventHistories,
    pager,
  };
};
