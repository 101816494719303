import json from '@/api/apiMock/data_files/datadog_monitor_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/datadog/get.types';
import { DatadogEventHistory } from '@/models/DatadogEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      priority: obj.priority,
      type: obj.data?.event_type,
      url: obj.source_url,
      target: obj.target,
      title: obj.title,
      status: obj.status,
      event_time: Number(obj.data.last_updated),
    };
    return new DatadogEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const datadogEventHistories = events as DatadogEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);

  return {
    datadogEventHistories,
    pager,
  };
};
