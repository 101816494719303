import { SearchParams } from './summary.types';
import { request } from '@/utils/api_client/sreshine';
export const summary = async (searchParams: SearchParams) => {
  const { data } = await request.get('/costs/', searchParams);
  const constructedData = {
    header: ['name', 'count'],
    data: [] as { name: string; value: number }[],
  };
  data['buckets'].forEach((bucket: any) => {
    constructedData.data.push({ name: bucket.key, value: bucket.value });
  });
  return { data: constructedData } as any;
};
