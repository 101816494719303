import { User } from '@/models/User';

export class AppName {
  label: string;
  name: string;
  users: User[];
  constructor(data: any) {
    this.label = data.label || '';
    this.name = data.name || '';
    this.users = data.users?.map((user: any) => new User(user)) || [];
  }
}
