import { ref, inject, readonly, Ref } from 'vue';

// provider
export const createFormErrors = () => {
  const formErrors = ref(new Map<string, string>());
  return {
    formErrors,
  };
};

// inject
export const keyFormErrors = Symbol();

/**
 * 各フォームごとにエラーを手動で表示させる場合に使う。
 * サーバレスポンスや複雑なバリデーションで使用する。
 */
export const useFormErrors = () => {
  const { formErrors } = inject(keyFormErrors) as {
    formErrors: Ref<Map<string, string>>;
  };
  //
  const setFormErrors = (errors: { id: string; message: string }[]) => {
    const map = new Map<string, string>();
    for (const error of errors) {
      map.set(error.id, error.message);
    }
    formErrors.value = map;
  };
  //
  const removeFormErrors = (key: string) => {
    // map.deleteだとwatchが発火しない
    const map = new Map<string, string>();
    formErrors.value.forEach((v, k) => {
      if (k !== key) {
        map.set(k, v);
      }
    });
    formErrors.value = map;
  };
  const formErrorMessage = (name: string) => {
    return formErrors.value.get(name);
  };

  return {
    formErrors: readonly(formErrors),
    setFormErrors,
    removeFormErrors,
    formErrorMessage,
  };
};
