import { IntegrationNewRelic } from './IntegrationNewRelic';
import { IntegrationDatadog } from './IntegrationDatadog';
import { IntegrationAws } from './IntegrationAws';
import { IntegrationAwsV2 } from './IntegrationAwsV2';

export class IntegrationList {
  newRelic: IntegrationNewRelic | null;
  datadog: IntegrationDatadog | null;
  aws: IntegrationAws | null;
  aws_v2: IntegrationAwsV2 | null;
  constructor(data: any) {
    if (data.new_relic) {
      this.newRelic = new IntegrationNewRelic(data.new_relic);
    } else {
      this.newRelic = null;
    }
    if (data.datadog) {
      this.datadog = new IntegrationDatadog(data.datadog);
    } else {
      this.datadog = null;
    }
    if (data.aws) {
      this.aws = new IntegrationAws(data.aws);
    } else {
      this.aws = null;
    }
    if (data.aws_v2) {
      this.aws_v2 = new IntegrationAwsV2(data.aws_v2);
    } else {
      this.aws_v2 = null;
    }
  }
}
