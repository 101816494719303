import { DateWithZone } from '@/models/DateWithZone';

export class NotificationChannel {
  id: string;
  name: string;
  type: "slack" | "email";
  webhookUrl: string | null;
  email: string | null;
  status: "not_certified" | "certified" | null;
  createdAt: DateWithZone;

  constructor(data: any) {
    this.id = data.id || '';
    this.name = data.name || '';
    this.type = data.type || '';
    this.webhookUrl = data.webhook_url || null;
    this.email = data.to || null;
    this.status = data.status || null;
    this.createdAt = new DateWithZone(data.created_at);
  }
}
