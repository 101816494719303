import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// prettier-ignore
const routes: Array<RouteRecordRaw> = [
  { path: '/', component: () => import('@/views/containers/dashboard.vue') },
  // auth
  {
    path: '/auth/sign_in',
    component: () => import('@/views/containers/auth/sign_in.vue'),
  },
  {
    path: '/auth/sign_out',
    component: () => import('@/views/containers/auth/sign_out.vue'),
  },
  // dashboard
  {
    path: '/dashboard',
    component: () => import('@/views/containers/dashboard.vue'),
  },
  {
    path: '/aws/ec2/instance_state',
    component: () => import('@/views/containers/aws/ec2/instance_state.vue'),
  },
  {
    path: '/aws/ec2/spot_instance_interruption',
    component: () => import('@/views/containers/aws/ec2/spot_instance_interruption.vue'),
  },
  {
    path: '/aws/ecs',
    component: () => import('@/views/containers/aws/ecs.vue'),
  },
  {
    path: '/aws/health',
    component: () => import('@/views/containers/aws/health.vue'),
  },
  {
    path: '/aws/guardduty',
    component: () => import('@/views/containers/aws/guardduty.vue'),
  },
  {
    path: '/aws/inspector/ec2_network',
    component: () =>
      import('@/views/containers/aws/inspector/ec2NetworkEvent.vue'),
  },
  {
    path: '/aws/inspector/ec2_package',
    component: () =>
      import('@/views/containers/aws/inspector/ec2PackageEvent.vue'),
  },
  {
    path: '/aws/inspector/ecrImage',
    component: () => import('@/views/containers/aws/inspector/ecrImage.vue'),
  },
  {
    path: '/aws/cloudwatch',
    component: () => import('@/views/containers/aws/cloudwatch.vue'),
  },
  {
    path: '/aws/rds',
    component: () => import('@/views/containers/aws/rds.vue'),
  },
  {
    path: '/aws/securityhub',
    component: () => import('@/views/containers/aws/securityhub.vue'),
  },
  { path: '/sentry', component: () => import('@/views/containers/sentry.vue') },
  {
    path: '/datadog',
    component: () => import('@/views/containers/datadog.vue'),
  },
  {
    path: '/pagerduty',
    component: () => import('@/views/containers/pagerduty.vue'),
  },
  {
    path: '/newrelic',
    component: () => import('@/views/containers/newrelic.vue'),
  },
  // { path: '/oncall', component: () =>  import('@/views/containers/oncall.vue') }, // 一旦除外
  {
    path: '/notices',
    component: () => import('@/views/containers/notices.vue'),
  },
  {
    path: '/costs',
    component: () => import('@/views/containers/costs.vue'),
  },
  // settings
  {
    path: '/settings/organizations/products',
    component: () =>
      import('@/views/containers/settings/organizations/products.vue'),
  },
  {
    path: '/settings/organizations/users',
    component: () =>
      import('@/views/containers/settings/organizations/users.vue'),
  },
  {
    path: '/settings/products/integrations',
    component: () =>
      import('@/views/containers/settings/products/integrations.vue'),
  },
  {
    path: '/settings/personal/profile',
    component: () => import('@/views/containers/settings/personal/profile.vue'),
  },
  {
    path: '/settings/personal/timezone',
    component: () =>
      import('@/views/containers/settings/personal/timezone.vue'),
  },
  {
    path: '/settings/products/api_keys',
    component: () =>
      import('@/views/containers/settings/products/api_keys.vue'),
  },
  {
    path: '/settings/products/notifications/channels',
    component: () =>
      import('@/views/containers/settings/products/notifications/channels.vue'),
  },
  {
    path: '/settings/products/notifications/settings',
    component: () =>
      import('@/views/containers/settings/products/notifications/settings.vue'),
  },
  {
    path: '/settings/verification_email',
    component: () =>
      import('@/views/containers/settings/verification_email.vue'),
  },
  {
    path: '/tasks',
    component: () =>
      import('@/views/containers/tasks/index.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/containers/not_found.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
