import { request } from '@/utils/api_client/sreshine';

export const deleteIntegtaion = async (
  params: ParamDeleteIntegration
): Promise<any> => {
  return await request.delete(`/products/integrations/new_relic/delete`, {
    ...params,
  });
};

export type ParamDeleteIntegration = {
  organization: string;
  app_name: string;
};
