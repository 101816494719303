<script setup lang="ts">
import { ref, watch, Ref } from 'vue';
import { useSnackbarMessage } from '@/models/global/snackbarMessage';
const isOpen = ref(false);
const message = ref('');
const type: Ref<'' | 'success' | 'error'> = ref('');

// 起動
const { snackbarMessage, setSnackbarMessage } = useSnackbarMessage();
watch(snackbarMessage, (value) => {
  if (value.message !== '') {
    isOpen.value = true;
  } else {
    isOpen.value = false;
  }
  if (value.type === 'success') {
    type.value = 'success';
  } else if (value.type === 'error') {
    type.value = 'error';
  } else {
    type.value = '';
  }
  message.value = value.message;
});

watch(isOpen, (value) => {
  if (value === false) {
    // フェードアウト分、少し遅らせる
    setTimeout(() => {
      setSnackbarMessage({ message: '' });
    }, 200);
  }
});
</script>
<template>
  <VSnackbar v-model="isOpen" :timeout="4000" :color="type" location="top">
    <div>{{ message }}</div>
  </VSnackbar>
</template>
