import { ref, Ref, inject } from 'vue';
import timezone from '@/models/global/timezone';
import { Organization } from '@/models/Organization';

// 型定義
export interface CurrentUser {
  username: string;
  email: string;
  challengeName: '' | 'NEW_PASSWORD_REQUIRED' | 'MFA_REQUIRED' | 'SMS_MFA';
  isTester: boolean;
  organizations: Organization[];
  imageUrl: string;
  timezone: string;
}

// Provider
export const createCurrentUser = () => {
  const currentUser: Ref<CurrentUser> = ref({
    username: '',
    email: '',
    challengeName: '',
    isTester: false,
    organizations: [],
    imageUrl: '',
    timezone: '',
  } as CurrentUser);
  return {
    currentUser,
  };
};

// Inject
export const keyCurrentUser = Symbol();
export const useCurrentUser = () => {
  const { currentUser } = inject(keyCurrentUser) as {
    currentUser: Ref<CurrentUser>;
  };
  const setCurrentUser = (user: CurrentUser) => {
    const model = {
      username: user.username,
      email: user.email,
      challengeName: user.challengeName,
      // 特定アカウントはテスターになる
      isTester:
        user.username === 'tester' || user.email?.split('@')[1] === 'demo.srest'
          ? true
          : false,
      // organizationは、setCurrentUserで変更されない
      organizations: currentUser.value.organizations,
      imageUrl: user.imageUrl,
      timezone: user.timezone,
    };
    currentUser.value = model;
    timezone.commit('setTimezone', user.timezone);
    return;
  };
  const setOrganizations = (organizations: Organization[]) => {
    currentUser.value.organizations = organizations;
  };
  const clearCurrentUser = () => {
    currentUser.value = {
      username: '',
      email: '',
      challengeName: '',
      isTester: false,
      organizations: [],
      imageUrl: '',
      timezone: '',
    };
  };
  return {
    currentUser: currentUser,
    setCurrentUser,
    setOrganizations,
    clearCurrentUser,
  };
};
