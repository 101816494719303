type WithAppName<T = Record<string, unknown>> = T & { appName: string };

export const countAppNames = <T = Record<string, unknown>>(
  data: WithAppName<T>[]
) => {
  const counts = data.reduce<Record<string, number>>((acc, item) => {
    const appName = item.appName;
    acc[appName] = (acc[appName] || 0) + 1;
    return acc;
  }, {});

  const result = Object.entries(counts).map(([appName, count]) => [
    appName,
    count,
  ]);
  return result;
};
