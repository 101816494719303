import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';
import { DatadogSloEventHistory } from '@/models/DatadogSloEventHistory';

export const get = async (queryParams: QueryParams) => {
  const { data } = await request.get('/event/datadog_slo/index', queryParams);
  const datadogSloEventHistories =
    data.datadog_slo?.map((datadogSloEventHistory: any) => {
      return new DatadogSloEventHistory(datadogSloEventHistory);
    }) || [];
  return {
    datadogSloEventHistories,
  };
};
