import json from '@/api/apiMock/data_files/pagerduty_incident_events.json';
import { getFilter } from '@/api/apiMock/utils/filter';
import { recent } from '@/api/apiMock/utils/date/recent';
import { QueryParams } from '@/api/apiSreshine/pagerduty/get.types';
import { PagerdutyEventHistory } from '@/models/PagerdutyEventHistory';
import { Pager } from '@/models/Pager';

const jsonData = JSON.parse(JSON.stringify(json));

export const get = async (queryParams: QueryParams) => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const all = jsonData.data?.map((obj: any) => {
    const json = {
      organization: 'metaps',
      app_name: 'demo',
      status: obj.data?.event?.data?.status,
      title: obj.data?.event?.data?.title,
      html_url: obj.data?.event?.data?.html_url,
      oncall_persons:
        obj.data?.event?.data?.assignees?.map(
          (assignee: any) => assignee.summary
        ) || '',
      event_time: obj.data?.event?.occurred_at,
    };
    return new PagerdutyEventHistory(json);
  });
  recent(all);
  const { events, headers } = getFilter(all, queryParams);

  const pagerdutyEventHistories = events as PagerdutyEventHistory[];
  const pager = new Pager();
  pager.setLinkHeader(headers);

  return {
    pagerdutyEventHistories,
    pager,
  };
};
