import { request } from '@/utils/api_client/sreshine';
import type { Priority, Status } from '@/models/Task';
import type { EventHistory } from '@/models/EventHistory';
import type { DateWithZone } from '@/models/DateWithZone';

export const create = async (params: CreateParams): Promise<any> => {
  return await request.post(`/task_management/tasks`, {
    ...params,
  });
};

export type CreateParams = {
  title: string;
  priority: Priority;
  status: Status;
  assignees: string[];
  dueDate: DateWithZone | undefined;
  description: string;
  organization: string;
  event: EventHistory;
};
