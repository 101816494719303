import { AwsEcsEventHistory } from '@/models/AwsEcsEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/ecs/index',
    queryParams
  );
  const awsEcsEventHistories: AwsEcsEventHistory[] =
    data.aws_ecs_events?.map(
      (awsEcsEventHistory: any) => new AwsEcsEventHistory(awsEcsEventHistory)
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsEcsEventHistories,
    pager,
  };
};
