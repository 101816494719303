import { AwsEc2SpotInstanceInterruptionEventHistory } from '@/models/AwsEc2SpotInstanceInterruptionEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/ec2/spot_instance_interruption/index',
    queryParams
  );

  const awsEc2SpotInstanceInterruptionEventHistories: AwsEc2SpotInstanceInterruptionEventHistory[] =
    data.aws_ec2_events?.map(
      (awsEc2SpotInstanceInterruptionEventHistory: any) =>
        new AwsEc2SpotInstanceInterruptionEventHistory(
          awsEc2SpotInstanceInterruptionEventHistory
        )
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsEc2SpotInstanceInterruptionEventHistories,
    pager,
  };
};
