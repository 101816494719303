import { AwsInspectorEc2NetworkEventHistory } from '@/models/AwsInspectorEc2NetworkEventHistory';
import { Pager } from '@/models/Pager';
import { request } from '@/utils/api_client/sreshine';
import { QueryParams } from './get.types';

export const get = async (queryParams: QueryParams) => {
  const { data, headers } = await request.get(
    '/event/aws/inspector/ec2_network/index',
    queryParams
  );
  const awsInspectorEc2NetworkEventHistories: AwsInspectorEc2NetworkEventHistory[] =
    data.aws_inspector_events?.map(
      (awsInspectorEc2NetworkEventHistory: any) =>
        new AwsInspectorEc2NetworkEventHistory(
          awsInspectorEc2NetworkEventHistory
        )
    ) || [];
  const pager = new Pager();
  pager.setLinkHeader(headers);
  return {
    awsInspectorEc2NetworkEventHistories,
    pager,
  };
};
